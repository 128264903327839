import React, { useState } from 'react';

import UserPopUp from './UserPopUp';

import styled from '@emotion/styled';
import { Avatar } from '@innovamat/glimmer-components';

const AccountContainer = styled.div`
  position: relative;
  > div:first-of-type {
    cursor: pointer;
  }
`;

const PopupContainer = styled.div`
  position: absolute;
  right: 0;
  top: 45px;
`;

type User = {
  firstName: string;
  lastName: string;
  email: string;
  school?: string;
  crmId?: string;
};

type AccountProps = {
  user: User;
  translate: any;
  loadingAssessor: boolean;
  handleGetAssessor?: (crmId?: string, schoolId?: string) => void;
  handleGoToSettings?: () => void;
  handleLogout: () => void;
  setLoggingData: (event: string) => void;
};

function Account(props: AccountProps) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const toggleOpenPopup = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsPopupOpen((prevState) => !prevState);
  };

  const {
    user,
    translate,
    loadingAssessor,
    handleGetAssessor,
    handleGoToSettings,
    handleLogout,
    setLoggingData,
  } = props;

  const { firstName, lastName } = user;

  return (
    <AccountContainer data-inlinemanual="avatar">
      <Avatar
        // icon="UserIcon"
        text={`${firstName} ${lastName}`}
        onClick={(e) => toggleOpenPopup(e)}
        // alt={`account-${firstName} ${lastName}`}
      />
      {isPopupOpen && (
        <PopupContainer>
          <UserPopUp
            handleClose={() => setIsPopupOpen(false)}
            user={user}
            handleGetAssessor={handleGetAssessor}
            handleGoToSettings={handleGoToSettings}
            handleLogout={handleLogout}
            setLoggingData={setLoggingData}
            translate={translate}
            loadingAssessor={loadingAssessor}
          />
        </PopupContainer>
      )}
    </AccountContainer>
  );
}

export default Account;
