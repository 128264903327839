import { apolloFetch } from '../../utils/apolloFetch';
import { getUserAcceptLanguage } from '../use-user/utils';

export const getRegionParameters = async (
  regionCode: string,
  stage: string
) => {
  const acceptLanguage = getUserAcceptLanguage();
  const graphQl = JSON.stringify({
    query: ` query RegionParameters($regionCode: String!, $stage: String){
      regionParameters(regionCode: $regionCode, stage: $stage)
      } `,
    variables: { regionCode, stage },
  });
  const response = await apolloFetch({
    graphQl,
    headers: { 'Accept-Language': acceptLanguage },
  });
  return response.data?.regionParameters;
};
