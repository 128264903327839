import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useUser } from '../use-user';
import type { TopBanner } from './top-banner';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { getRemainingDays } from '../../utils/date';

export function useTopBanner() {
  const queryClient = useQueryClient();
  const { isRole, trialExpirationDate, language } = useUser();
  const { t } = useTranslation();
  const query = useQuery<TopBanner>({
    queryKey: ['top-banner', language],
    queryFn: () => {
      const hasTrial = getHasTrial();

      if (!hasTrial) {
        return {
          show: true,
          title: 'BannerSamples',
          subtitle: 'BannerSamples2',
        };
      }

      const remainingDays = getRemainingDays(trialExpirationDate);

      return {
        show: true,
        title: 'BannerTrialTitle',
        remainingDays,
      };
    },
    enabled: isRole.Lead || isRole.Free,
  });

  const getHasTrial = (): boolean => {
    if (!trialExpirationDate) return false;
    const now = DateTime.now();
    const expDate = DateTime.fromSQL(trialExpirationDate);
    const isInTrial = now.startOf('day') <= expDate.startOf('day');
    return isInTrial;
  };

  const closeBanner = (): void => {
    queryClient.setQueryData(['top-banner'], {
      show: false,
      title: '',
      subtitle: '',
    });
  };

  return {
    closeBanner,
    showBanner: Boolean(query.data?.show),
    bannerMessage: {
      title: t(`${query.data?.title}`, { days: query.data?.remainingDays }),
      subtitle: query.data?.subtitle ? t(query.data?.subtitle) : undefined,
    },
  };
}
