import { bannerHeight, sidebarWidth } from '../HomeLayout/HomeLayout.styled';
import { Drawer } from '@innovamat/innova-components';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  height: 100%;

  .goBackBtn {
    left: 24px;
    top: 40px;
    cursor: pointer;
    z-index: 2;
  }
`;

const FixedContent = styled.div<{ isCollapsed: boolean }>`
  background-color: ${(props) => props.theme.colors.white};
  position: fixed;
  width: ${sidebarWidth};
  padding-bottom: 24px;

  ${(props) =>
    props.isCollapsed &&
    css`
      padding: 64px 12px 24px 12px;
    `}
`;
const List = styled.ul`
  direction: ltr;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  margin-top: 64px;

  overflow: auto;
  height: calc(var(--realVh) - (170px + ${bannerHeight})) !important;
`;

const AvatarsContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
  margin-left: 24px;

  .menu {
    background-color: white !important;

    svg {
      path {
        stroke: ${(props) => props.theme.colors.dark['02']} !important;
      }
    }
  }
`;

const SidebarDrawer = styled(Drawer)`
  width: ${sidebarWidth};
`;

export { Wrapper, FixedContent, List, AvatarsContainer, SidebarDrawer };
