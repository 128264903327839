import { useQuery } from '@tanstack/react-query';
import { getPlatformApiUrl } from './api';
import { useUser } from '../use-user';

const DEFAULT_URL = import.meta.env.VITE_API_PLATFORM as string;

export function usePlatformUrl() {
  const { userId } = useUser();
  const query = useQuery<string>({
    queryKey: ['platformUrl'],
    queryFn: async () => getPlatformApiUrl(),
    staleTime: Infinity,
    enabled: Boolean(userId),
  });

  return {
    platformUrl: query.data || DEFAULT_URL,
  };
}
