import { Button } from '@innovamat/innova-components';
import { Topbar } from '@innovamat/ga-components';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styles from './StudentWithoutAccess.module.scss';
import { useUser } from '../../hooks/use-user';

const StudentWithoutAccess = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { onLogout } = useUser();

  const handleLogout = () => {
    onLogout();
    history.push('/');
  };

  return (
    <div>
      <Topbar
        showHelpButton={false}
        handleLogout={() => {}}
        loadingAssessor={false}
        routes={[]}
        translate
        user={{} as any}
        setLoggingData={() => {}}
      />
      <div className={styles.container}>
        <h3>
          {t(
            'No estás asignado a ninguna clase, habla con tu tutor para solucionarlo.'
          )}
        </h3>

        <div>
          <Button onClick={handleLogout}>{t('Cerrar sesión')}</Button>
        </div>
      </div>
    </div>
  );
};

export default StudentWithoutAccess;
