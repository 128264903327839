import { gql } from 'graphql-request';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { BannersFilter, BannersResponse } from '../types';
import request from 'graphql-request';
import { useSitemapItems } from '../../../hooks/use-sitemap/use-sitemap-items';

const graphEndpoint = import.meta.env.VITE_PREPR_GRAPHQL;

const GET_BANNERS = gql`
  query CMBanners($where: CMBannerWhereInput, $locale: String!) {
    CMBanners(where: $where, locale: $locale) {
      items {
        _id
        section_tr
        section_dp
        section_cv
        colour
        cta_link
        cta_text
        cta
        text
        title
        cta_external
        can_be_closed
        demo_users
        inline_topic_id
        only_for_assessment
        only_for_intervention
        icon {
          _id
          name
          description
          width
          height
          url
        }
        importance
        roles {
          ... on ClassroomManagerRoles {
            roles
          }
        }
      }
    }
  }
`;

type Props = {
  filters: BannersFilter | undefined;
};

export function useGetBanners({
  filters,
}: Props): UseQueryResult<BannersResponse[], Error> {
  const { getPageFromSitemap } = useSitemapItems();

  const query = useQuery<BannersResponse[], Error>({
    queryKey: ['banners', filters?.course, filters?.locale],
    queryFn: async () => {
      if (!filters) return [];
      const { locale, course, region, roles } = filters;
      const variables = {
        where: {
          active: true,
        },
        locale,
      };

      if (course) {
        variables.where[`course_${course}`] = true;
      }

      if (region) {
        variables.where[region.toLocaleLowerCase().replace('-', '_')] = true;
      }

      const page = getPageFromSitemap();
      const hasDemo = !page?.fullPageId;

      const banners: any = await request(graphEndpoint, GET_BANNERS, variables);

      const filterByRole = (banner: any) => {
        const bannerRoles = banner.roles.map((role: any) => role.roles);
        return roles?.some((role) =>
          bannerRoles.includes(role.toLocaleLowerCase())
        );
      };

      const filterByPermission = (banner: any) => {
        return banner.demo_users ? hasDemo : true;
      };

      const filteredBanners: BannersResponse[] =
        banners?.CMBanners?.items
          ?.filter(filterByRole)
          .filter(filterByPermission)
          .map((banner: any) => {
            return {
              id: banner._id,
              colour: banner.colour,
              cta: banner.cta,
              ctaLink: banner.cta_link,
              ctaText: banner.cta_text,
              icon: {
                height: banner.icon?.[0]?.height,
                name: banner.icon?.[0]?.name,
                url: banner.icon?.[0]?.url,
                width: banner.icon?.[0]?.width,
              },
              importance: banner.importance,
              sectionCv: banner.section_cv,
              sectionDp: banner.section_dp,
              sectionTr: banner.section_tr,
              text: banner.text,
              title: banner.title,
              ctaExternal: banner.cta_external,
              canBeClosed: banner.can_be_closed,
              inlineTopicId: banner.inline_topic_id,
            } as BannersResponse;
          }) || [];

      return filteredBanners;
    },

    enabled: Boolean(filters && filters.locale),
  });

  return query;
}
