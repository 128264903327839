import { stages } from '@innovamat/radiance-utils';
import { setEventData } from '../utils/setEventData';
import { useGetEventData } from './useGetEventData';
import { useUser } from './use-user';
import { useStudentView } from './use-student-view';

type SetEventProps = {
  resourceName?: string;
  resourceType?: string;
  id?: string;
  sessionName?: string;
  topMenu?: string;
  pageId?: string;
  pageName?: string;
  pageType?: string;
  buttonLocation?: 'top menu' | 'overlay' | 'icon';
};

export default function useStudentViewValues() {
  const { isStudentViewActive, onToggle } = useStudentView();
  const { courseOrder, currentClassroom: classroom, isRole } = useUser();

  const { id, pageId, pageName, pageType, topMenu, getResourceData } =
    useGetEventData();

  const showToggleToStudent =
    (isRole.Teacher || isStudentViewActive) &&
    stages.isSecundaria(courseOrder) &&
    classroom;

  const handleStudentViewToggle = (props: SetEventProps) => {
    const key: string = isStudentViewActive
      ? 'back_to_teacher_view'
      : 'switch_to_student_view';

    const buttonLocation = isStudentViewActive
      ? props.buttonLocation
      : undefined;

    const isResource = getResourceData()?.type !== 'session';

    setEventData(key, {
      resource_id: isResource ? id : null,
      resource_name: isResource ? getResourceData()?.title : null,
      resource_type: isResource ? getResourceData()?.type : null,
      session_id: !isResource ? id : null,
      session_name: !isResource ? getResourceData()?.title : null,
      top_menu: topMenu,
      page_id: pageId,
      page_name: pageName,
      page_type: pageType,
      button_location: buttonLocation,
    });

    onToggle();
  };

  return { handleStudentViewToggle, showToggleToStudent, isStudentViewActive };
}
