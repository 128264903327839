import { Roles } from '@innovamat/radiance-utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useUser } from '../use-user';

export function useStudentView() {
  const { updateRoles, roles } = useUser();
  const queryClient = useQueryClient();
  const { data: previousRoles } = useQuery<Roles[] | undefined>({
    queryKey: ['previousRoles'],
    staleTime: Infinity,
    initialData: undefined,
  });

  const isStudentViewActive = Boolean(previousRoles);

  const onToggle = () => {
    if (!previousRoles?.length) {
      queryClient.setQueryData(['previousRoles'], roles);
      updateRoles([Roles.STUDENT]);
    } else {
      updateRoles(previousRoles);
      queryClient.setQueryData(['previousRoles'], undefined);
      queryClient.removeQueries({ queryKey: ['previousRoles'] });
    }
  };

  return {
    isStudentViewActive,
    onToggle,
  };
}
