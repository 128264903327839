import { Classroom } from '@innovamat/radiance-utils';

export const setTeacherWithNoClass = (
  platformEnabled: boolean,
  classrooms: Classroom[] | undefined
) => {
  const element = document.getElementById('root');

  if (platformEnabled && classrooms?.length === 0) {
    element?.setAttribute('data-attribute', 'teacherwithnoclass');
  } else {
    element?.removeAttribute('data-attribute');
  }
};

export const setInlineManualCourse = (course: number) => {
  const element = document.getElementById('root');
  element?.setAttribute('data-inlinemanual', `course_order:${course}`);
};
