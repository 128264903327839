import { Course, sortBy, storage } from '@innovamat/radiance-utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getCourses } from './api';
import { User, useUser } from '../use-user';

export function useCourses() {
  const queryClient = useQueryClient();
  const { region, currentCourse, currentClassroom, isRole } = useUser();

  const coursesQuery = useQuery<Course[] | undefined>({
    queryKey: ['courses'],
    queryFn: async () => {
      if (!region) return;
      const courses = await getCourses(region);
      const orderedCourses = courses.concat().sort(sortBy('order'));
      onSetCourseInUser(orderedCourses);
      return orderedCourses;
    },
    staleTime: Infinity,
    enabled: Boolean(region),
  });

  const setDefaultCourse = (courses: Course[]): void => {
    const getCourse = (courseOrder: number): Course | undefined => {
      return courses?.find((c) => c.order === courseOrder);
    };

    const defaultCourse = getCourse(6);
    storage.course.set(defaultCourse as Course);
    queryClient.setQueryData<User | undefined>(['user'], (oldData) => {
      if (!oldData) return oldData;
      return {
        ...oldData,
        navigation: {
          ...oldData.navigation,
          currentCourse: defaultCourse,
        },
      };
    });
  };

  const onSetCourseInUser = (courses: Course[] | undefined): void => {
    if (currentClassroom || isRole.Teacher) return;

    if (
      !currentCourse ||
      !courses?.some((c) => c.id === currentCourse.id) ||
      isRole.Lead
    ) {
      setDefaultCourse(courses as Course[]);
    }
  };

  return {
    ...coursesQuery,
    courses: coursesQuery.data,
    setDefaultCourse,
  };
}
