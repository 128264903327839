import type { OrganizationResponse, Preferences } from './user.d';

import { apolloFetch } from '../../utils/apolloFetch';
import { authAxiosInstance } from '@innovamat/ga-features';

const INTEGROMAT = import.meta.env.VITE_INTEGROMAT_GET_CRM_SCHOOL;

export const getPreferences = async (
  apiUsers: string
): Promise<Preferences> => {
  const url = `${apiUsers}/user/me/preferences`;
  return await authAxiosInstance({ url, method: 'GET' }).then(
    (res) => res.data
  );
};

export const getSchoolName = async (crmId: string): Promise<string> => {
  const url = `${INTEGROMAT}?crm_id=${crmId}`;
  return await authAxiosInstance({ url, method: 'GET' }).then(
    (res) => res.data
  );
};

export const getStudent = async (id: string) => {
  const graphQl = JSON.stringify({
    query: `query Student($id: ID!) {
      student(id: $id) {
        classroomName
        courseName
        region
        icon
        courseOrder
        classroomId
        classroomIcon
        classroomAvatar
        courseId
        classroomLevel
      }
    }`,
    variables: { id },
  });
  const response = await apolloFetch({ graphQl });
  return response.data?.student;
};

export const getOrganization = async (
  id: string
): Promise<OrganizationResponse> => {
  const graphQl = JSON.stringify({
    query: `query Organization($id: ID!) {
      organization(id: $id) {
        id
        name
        location
        region
        availableLanguages
        platformEnabled
        operativeRegion
        language
        academicYearId
        currency
        standard
      }
    }`,
    variables: { id },
  });
  const response = await apolloFetch({ graphQl });
  return response.data?.organization;
};
