import { lazy, Suspense, FC } from 'react';

const withSuspense = <P extends {}>(LazyComponent: FC<P>) => {
  return (props: P) => (
    <Suspense fallback={<></>}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

const LazyE40X = withSuspense(lazy(() => import('../components/Errors/E40X')));
const LazyMaintenance = withSuspense(
  lazy(() => import('../components/Errors/Maintenance'))
);
const LazyE500 = withSuspense(lazy(() => import('../components/Errors/E500')));
const LazyHomeLayout = withSuspense(
  lazy(() => import('../components/HomeLayout'))
);
const LazyResourceLayout = withSuspense(
  lazy(() => import('../components/ResourceLayout'))
);
const LazyLeadRegister = withSuspense(
  lazy(() => import('../components/LeadRegister'))
);
const LazyLinkSchool = withSuspense(
  lazy(() => import('../components/LinkSchool'))
);
const LazyTestWebGl = withSuspense(
  lazy(() => import('../components/TestWebGL'))
);
const LazyResetPassword = withSuspense(
  lazy(() => import('../components/ResetPassword'))
);
const LazyForgotPassword = withSuspense(
  lazy(() => import('../components/ForgotPassword'))
);
const LazyRegisterUser = withSuspense(
  lazy(() => import('../components/RegisterUser/RegisterUser'))
);
const LazyManipulative = withSuspense(
  lazy(() => import('../components/ManipulativeEnvironment'))
);

const resourcesPaths = [
  { path: '/:menu/session/:id', component: LazyResourceLayout },
  { path: '/:menu/doc/:id', component: LazyResourceLayout },
  { path: '/:menu/pdf/:id', component: LazyResourceLayout },
  { path: '/:menu/video/:id', component: LazyResourceLayout },
  { path: '/:menu/applet/:id', component: LazyResourceLayout },
  { path: '/:menu/geogebra/:id', component: LazyResourceLayout },
];
const LazyAppletSelector = withSuspense(
  lazy(() => import('../components/applet-selector'))
);

export const authPaths = [
  { path: '/manipulativeEnvironment', component: LazyManipulative },
  { path: '/manipulative', component: LazyManipulative },
  { path: '/forbidden', component: LazyE40X },
  { path: '/maintenance', component: LazyMaintenance },
  { path: '/error', component: LazyE500 },
  { path: '/:menu/page/:id', component: LazyHomeLayout },
  { path: '/:menu/session/:id', component: LazyResourceLayout },
  { path: '/:menu/doc/:id', component: LazyResourceLayout },
  { path: '/:menu/pdf/:id', component: LazyResourceLayout },
  { path: '/:menu/video/:id', component: LazyResourceLayout },
  { path: '/:menu/applet/:id', component: LazyResourceLayout },
  { path: '/:menu/geogebra/:id', component: LazyResourceLayout },
  { path: '/settings', component: LazyHomeLayout },
  { path: '/reports', component: LazyHomeLayout },
  { path: '/applets', component: LazyAppletSelector },
  // These paths must be on auth side to accept the route if you already logged and then logout
  {
    path: '/:lng/reset-password',
    component: LazyResetPassword,
  },
  {
    path: '/:lng/link-school',
    component: LazyLinkSchool,
  },
  {
    path: '/:lng/lead-register',
    component: LazyLeadRegister,
  },
  {
    path: '/:lng/complete-user-invitation',
    component: LazyRegisterUser,
  },
  {
    path: '/:lng/complete-parent-invitation',
    component: LazyRegisterUser,
  },
  { path: '/landing-parent', component: LazyHomeLayout },
  {
    path: '/unity-test/:stage/:sceneName/:packOrVariation',
    component: LazyTestWebGl,
  },
  {
    path: '/maintenance',
    component: LazyMaintenance,
  },
];

export const authRouteWithoutAuth = [
  'complete-parent-invitation',
  'complete-user-invitation',
  'lead-register',
  'link-school',
  'reset-password',
];

export const otherPaths = [
  {
    path: '/:lng/reset-password',
    component: LazyResetPassword,
  },
  {
    path: '/:lng/link-school',
    component: LazyLinkSchool,
  },
  {
    path: '/:lng/lead-register',
    component: LazyLeadRegister,
  },
  {
    path: '/:lng/complete-user-invitation',
    component: LazyRegisterUser,
  },
  {
    path: '/:lng/complete-parent-invitation',
    component: LazyRegisterUser,
  },
  {
    path: '/:lng/forgot-password',
    component: LazyForgotPassword,
  },
  ...resourcesPaths,
];
