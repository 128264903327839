import { useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';

import { setEventData } from '../../utils/setEventData';

import BannerContainer from '../../features/banners/components/BannerContainer';
import styled from 'styled-components';
import { useMedia } from '@innovamat/hooks';
import { useSitemapItems } from '../../hooks/use-sitemap/use-sitemap-items';
import { useUser } from '../../hooks/use-user';
import {
  LazyE404,
  LazyPage,
  LazyPrintGuides,
  LazyReports,
  LazyResourceRouter,
  LazySettings,
  LazyWeeklyPractice,
} from '../HomeLayout/LazyLoader';
import { useWebviewBridge } from '@innovamat/webview-bridge';

const StyledBanner = styled(BannerContainer)`
  margin-bottom: 32px;
`;

export default function ContentRouter() {
  const {
    sitemaps,
    getPageFromSitemap,
    getTypeOfPagePermission,
    topMenu,
    isSuccess,
    isError,
  } = useSitemapItems();
  const { isRole, language, region, courseOrder, roles, isLoggedIn } =
    useUser();
  const isMobile = useMedia(600);
  const subMenus =
    sitemaps
      ?.flatMap((menuItem) => menuItem.items)
      .filter((menuItem) => menuItem.type === 'link')
      .filter((menuItem) => menuItem.linkItemType === 'PAGE_ID') || [];
  const menuItem = getPageFromSitemap();

  const getPageValue = (urlName: string) => urlName?.split('/')[2] || 'page';
  const history = useHistory();

  const { isInWebview, isGreenApp } = useWebviewBridge();

  const handleSendEvent = (key: string, payload = {}) => {
    const pageId = menuItem?.fullPageId || menuItem?.demoPageId;
    if (menuItem) {
      const permissionType = getTypeOfPagePermission(menuItem);
      const pageName = menuItem.value;
      setEventData(key, {
        top_menu: topMenu,
        page_id: pageId,
        page_name: pageName,
        page_type: permissionType,
        ...payload,
      });
    }
  };

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        handleSendEvent('change_page');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.action]);

  if (isError) return <LazyE404 />;

  if (!isSuccess && isLoggedIn) return null;

  return (
    <Switch>
      {isRole.Student && isInWebview && isGreenApp && (
        <Redirect exact from="/" to="/practice/home" />
      )}
      <Redirect exact from="/" to="/curriculum/home" />

      {isRole.Student && (
        <Route
          exact
          path={['/practice', '/practice/weekly-practice', '/practice/home']}
          render={() => <LazyWeeklyPractice />}
        />
      )}

      <Route path="/:menu/reports" component={LazyReports} />

      <Route
        path="/:menu/page/:id"
        render={() => <LazyPage title="" type="page" />}
      />

      <Route path="/:menu/print/:ids" component={LazyPrintGuides} />
      <Route path="/:menu/:type/:id" component={LazyResourceRouter} />

      {!isRole.Student && (
        <Route path="/settings" render={() => <LazySettings />} />
      )}

      {subMenus?.map((submenu, index) => {
        const pageId = submenu.fullPageId || submenu.demoPageId;

        return (
          <Route
            key={`route-${submenu.urlName}-${index}`}
            exact
            path={submenu.urlName!}
            render={() => (
              <>
                {!isMobile && (
                  <StyledBanner
                    key={`banner-${submenu.urlName}-${index}`}
                    onSendEvent={handleSendEvent}
                    course={courseOrder}
                    urlName={submenu.urlName}
                    language={language}
                    region={region}
                    roles={roles}
                  />
                )}

                <LazyPage
                  key={`page-${submenu.urlName}-${index}`}
                  title={submenu.value!}
                  pageId={pageId}
                  type={getPageValue(submenu.urlName!)}
                />
              </>
            )}
          />
        );
      })}
      <Route path="*" component={LazyE404} />
    </Switch>
  );
}
