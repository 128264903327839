import { useRef } from 'react';
import { snack } from '@innovamat/glimmer-components';
import { Icon } from '@innovamat/innova-components';
import { Avatar } from '@innovamat/glimmer-components';
import ProfileLoader from './ProfileLoader';

import { useOnClickOutside } from '@innovamat/hooks';
import {
  Container,
  AvatarContainer,
  InfoContainer,
  Actions,
  Action,
  UserInfo,
  IconContainer,
  CopyClipboard,
  LogOutContainer,
  LogOut,
  StyledPhone,
  WhatsappIcon,
  StyledEmail,
  AssessorTitle,
} from './UserPopUp.styled';

type Assessor = {
  Email: string;
  Icon: string;
  Name: string;
  Phone: string;
};

type User = {
  firstName: string;
  lastName: string;
  email: string;
  school?: string;
  crmId?: string;
  assessor?: Assessor;
};

type UserPopUpProps = {
  user: User;
  handleLogout: () => void;
  handleGetAssessor?: (crmId?: string, schoolId?: string) => void;
  setLoggingData: (event: string) => void;
  translate: any;
  handleGoToSettings?: () => void;
  handleClose: () => void;
  loadingAssessor: boolean;
};

const UserPopUp = (props: UserPopUpProps): JSX.Element => {
  const {
    user,
    handleLogout,
    handleGetAssessor,
    setLoggingData,
    translate,
    handleGoToSettings,
    handleClose,
    loadingAssessor = false,
  } = props;

  const { firstName, lastName, email, school, crmId, assessor } = user;
  const name = `${firstName} ${lastName}`;

  const ref = useRef(null);

  const handleSettings = () => {
    setLoggingData('sys_profile_click');
    handleGoToSettings && handleGoToSettings();
  };

  const handleCopyClipboard = () => {
    if (assessor) {
      navigator.clipboard.writeText(assessor.Email);
      snack.success(translate('common.action.paste.success'));
    }
    setLoggingData('sys_advisor_email_click');
  };

  const handleOpenAssessorInfo = () => {
    if (!assessor) {
      handleGetAssessor && handleGetAssessor(crmId, school);
    }
  };

  useOnClickOutside(ref, () => {
    handleClose();
  });

  const hasBorder = Boolean(handleGoToSettings || handleGetAssessor);

  return (
    <Container ref={ref} data-inlinemanual="userpopup">
      <UserInfo>
        <AvatarContainer>
          <Avatar text={name} alt={`user-${name}`} />
        </AvatarContainer>
        <InfoContainer>
          <div className="name">{name}</div>
          <div className="email">{email}</div>
        </InfoContainer>
      </UserInfo>
      <Actions hasBorder={hasBorder}>
        {handleGoToSettings && (
          <Action
            tabIndex={0}
            role="button"
            onClick={handleSettings}
            onKeyDown={handleSettings}
          >
            {translate('innovacomponents.userPopUp.settings')}
            <IconContainer className="iconContainer">
              <Icon icon="Settings" size="sm" />
            </IconContainer>
          </Action>
        )}

        {!assessor && handleGetAssessor && (
          <Action
            tabIndex={0}
            role="button"
            onClick={handleOpenAssessorInfo}
            onKeyDown={handleOpenAssessorInfo}
            data-inlinemanual="contactadvisor"
          >
            {translate('innovacomponents.userPopUp.contactYourAssessor')}
            <IconContainer className="iconContainer">
              <Icon icon="ChatUser" size="sm" />
            </IconContainer>
          </Action>
        )}
      </Actions>
      {assessor && !loadingAssessor ? (
        <div data-inlinemanual="advisorinfo">
          <AssessorTitle className="assessorTitle">
            {translate('innovacomponents.userPopUp.yourAssessor')}
          </AssessorTitle>
          <UserInfo className="userInfo">
            <AvatarContainer className="avatarContainer">
              <Avatar
                img={assessor.Icon}
                size={'M'}
                alt={`assessor-${assessor.Name}`}
              />
            </AvatarContainer>
            <InfoContainer className="infoContainer">
              <div className="name">{assessor.Name}</div>
              <StyledEmail
                onClick={handleCopyClipboard}
                onKeyDown={() => {}}
                tabIndex={0}
                role="button"
              >
                {assessor.Email}
                <CopyClipboard className="copyClipboard">
                  <Icon icon="Clipboard" size="sm" />
                </CopyClipboard>
              </StyledEmail>
              {assessor.Phone && assessor.Phone !== '0' && (
                <StyledPhone
                  href={`https://wa.me/${assessor.Phone.replace(' ', '')}`}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => setLoggingData('sys_advisor_phone_click')}
                >
                  <WhatsappIcon>
                    <Icon icon="Whatsapp" size="sm" />
                  </WhatsappIcon>
                  {assessor.Phone}
                </StyledPhone>
              )}
            </InfoContainer>
          </UserInfo>
        </div>
      ) : (
        loadingAssessor && <ProfileLoader />
      )}
      <LogOutContainer
        tabIndex={0}
        role="button"
        onClick={handleLogout}
        onKeyDown={handleLogout}
      >
        <LogOut>
          {translate('innovacomponents.userPopUp.logout')}
          <IconContainer className="iconContainer">
            <Icon icon="Logout" size="sm" />
          </IconContainer>
        </LogOut>
      </LogOutContainer>
    </Container>
  );
};

export default UserPopUp;
