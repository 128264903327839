import { Classroom } from '@innovamat/radiance-utils';
import { GroupedClassroom } from '../components/CourseDropdown/MyClassrooms/MyClassrooms';

export const sortClassrooms = (
  classrooms: Classroom[]
): Classroom[] | undefined => {
  if (!classrooms) return undefined;
  return classrooms.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
  });
};

export const getDefaultClassroom = (
  classrooms: Classroom[] | undefined
): Classroom | undefined => {
  if (!classrooms || classrooms.length <= 0) return undefined;
  const grouped = groupedClassrooms(classrooms);
  const firstKey = Object.keys(grouped)[0];
  return grouped[firstKey][0];
};

type GetClassroomAndCourse = {
  course: number;
  classroom: Classroom | null;
};

export const getClassroomAndCourse = (
  defaultClassroom: Classroom | null,
  classroomCookie: Classroom | null,
  courseCookie: number | null,
  isAnotherUser: boolean
): GetClassroomAndCourse => {
  let classroom = defaultClassroom;
  let classrooomCourseOrder = classroom?.courseOrder;
  let course = classrooomCourseOrder ?? 6;

  if (!isAnotherUser) {
    classroom = classroomCookie ?? classroom;
    classrooomCourseOrder = classroom?.courseOrder;
    course = classrooomCourseOrder ?? courseCookie ?? 6;
  }
  return { course, classroom };
};

export const groupedClassrooms = (
  classrooms: Classroom[]
): GroupedClassroom => {
  const grouped: GroupedClassroom = {};
  classrooms.forEach((classroom) => {
    const key = classroom.courseOrder;
    if (!grouped[key]) {
      grouped[key] = [];
    }
    grouped[key].push(classroom);
  });

  return grouped;
};
