import { Button, InnerHtml } from '@innovamat/innova-components';
import styled from 'styled-components';
// import { burgerMenuBreakpoint } from '@innovamat/manager';
import { ImageComponent } from '@innovamat/glimmer-components';

const burgerMenuBreakpoint = 1232;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  margin-left: 32px;
  margin-right: 32px;
  @media screen and (max-width: ${burgerMenuBreakpoint}px) {
    margin-left: 32px;
  }
`;

export const Image = styled(ImageComponent)`
  position: absolute;
  height: 117px;
  top: 0;
  left: 32px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}px) {
    height: 100px;
    top: 0;
    left: 16px;
  }
`;

export const Title = styled(InnerHtml)`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
`;

export const Text = styled(InnerHtml)`
  font-size: 14px;
  line-height: 20px;
`;

export const Content = styled.div<{ bg: string; showBtn?: boolean }>`
  min-height: 104px;
  width: 100%;
  display: flex;

  background-color: ${({ theme, bg }) =>
    bg === 'brand' ? theme.colors[bg]['04'] : theme.colors[bg]['05']};
  border-radius: 4px;
  margin-top: 32px;
  padding: 16px 16px 16px 184px;
  justify-content: space-between;

  > div {
    width: ${({ showBtn }) => (showBtn ? '70%' : '100%')};
    gap: 4px;
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}px) {
    padding: 16px 16px 16px 120px;
    > div {
      width: 100%;
      margin-bottom: 8px;
    }
    flex-direction: column;
  }
`;

export const StyledButton = styled(Button)<{ bg: string }>`
  align-self: flex-end;
  height: fit-content;
  background-color: ${({ theme, bg }) =>
    theme.colors[bg].dark['01']} !important;
  //TODO: the component Button changes height size when is loading.
  height: 42px;
`;

export const IconContainer = styled.div`
  position: absolute;
  top: calc(32px + 16px);
  right: 16px;
  cursor: pointer;
`;
