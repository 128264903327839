import { useLocation, useHistory, useParams } from 'react-router-dom';
import { getRegisterLanguagesByRegion } from '@innovamat/localization';
import i18n from '../config/i18n';
import useGetUserRegion from './useGetUserRegion';
import { onChangeHtmlLanguage } from '@innovamat/radiance-utils';

export default function useLanguage() {
  const location = useLocation();
  const history = useHistory();
  const { lng } = useParams<{ lng: string }>();

  const region = useGetUserRegion();
  const languages = getRegisterLanguagesByRegion(region);

  const setLanguageFromUrl = () => {
    const pathLanguage = location.pathname.split('/')[1];

    const urlLanguage =
      languages.find((l) => l === pathLanguage) || i18n.options.lng || 'es';

    i18n.changeLanguage(urlLanguage);
  };

  const changeUrlLanguage = (lang: string) => {
    const { pathname, search } = location;
    if (pathname === '/') return;
    const newPath = lng ? pathname.replace(lng, lang) : `/${lang}${pathname}`;
    history.push(`${newPath}${search}`);
  };

  const setHtmlLanguage = (language: string) => {
    onChangeHtmlLanguage(language);
  };

  const handleChangeTranslations = async (language: string) => {
    await i18n.changeLanguage(language);
  };

  return {
    setLanguageFromUrl,
    changeUrlLanguage,
    setHtmlLanguage,
    handleChangeTranslations,
  };
}
