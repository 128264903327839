import { apolloFetch } from '../../utils/apolloFetch';

export const getPlatformApiUrl = async () => {
  const graphQl = JSON.stringify({
    query: ` query Query {
      platformApiUrl
    } `,
  });
  const response = await apolloFetch({
    graphQl,
  });
  return response.data?.platformApiUrl;
};
