type TopMenu = 'curriculum' | 'practice' | 'learning';

export const getTopMenu = (pathname: string): TopMenu => {
  if (pathname === '/' || !pathname) return 'curriculum';
  return pathname.includes('curriculum')
    ? 'curriculum'
    : pathname.includes('practice')
    ? 'practice'
    : 'learning';
};
