import { Classroom } from '@innovamat/radiance-utils';
import { apolloFetch } from '../../utils/apolloFetch';

export const getClassrooms = async (orgId: string): Promise<Classroom[]> => {
  const graphQl = JSON.stringify({
    query: `query Classrooms($orgId: ID!, $mine: Boolean!) {
      classrooms(orgId: $orgId, mine: $mine) {
        id
        name
        courseId
        courseName
        courseOrder
        icon
        avatar
        level
        providerId
        provider
      }
    }`,
    variables: { orgId, mine: true },
  });
  const response = await apolloFetch({ graphQl });
  return response.data?.classrooms;
};
