import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import type { Sitemap, SitemapItem, TopItem } from './sitemap.d';
import { useUser } from '../use-user';
import { getSitemap } from './api';
import { useLocation } from 'react-router-dom';
import { useWebviewBridge } from '@innovamat/webview-bridge';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo } from 'react';
import { useStudentView } from '../use-student-view';

export function useSitemap() {
  const { t } = useTranslation();
  const {
    currentCourse,
    region,
    isRole,
    organization,
    language,
    onLogout,
    currentClassroom,
  } = useUser();
  const courseOrder = currentCourse?.order;

  const { isStudentViewActive } = useStudentView();

  const viewAs = isStudentViewActive ? 'student' : undefined;

  const level = currentClassroom?.level;

  const enabled = Boolean(courseOrder !== undefined && region !== undefined);

  const query = useQuery<Sitemap[], AxiosError>({
    queryKey: ['sitemap', courseOrder, region, viewAs, language, level],
    queryFn: () =>
      getSitemap({
        region,
        courseOrder,
        viewAs,
        level,
      }),
    enabled,
    staleTime: Infinity,
  });

  const { pathname } = useLocation();
  const { isInWebview } = useWebviewBridge();

  const isTeacher = isRole.Teacher;
  const isAdvisor = isRole.Advisor;
  const isAdmin = isRole.Admin;
  const isOwner = isRole.Owner;
  const isDistrictAdmin = isRole.DistrictAdmin;

  const imInManager = pathname.includes('manager');

  const platformEnabled = !!organization?.platformEnabled;

  useEffect(() => {
    if (query.isError && query.error?.response?.status === 403) {
      onLogout();
    }
  }, [query.isError, query.error, onLogout]);

  const sitemapRoutes: TopItem[] = useMemo(
    () =>
      query.data?.map((sitemap) => {
        const topMenu = sitemap.route.split('/')[1];
        return {
          show: true,
          inlineManualData: topMenu,
          title: sitemap.value,
          linkTo: sitemap.route,
          isActive: (_: any, location: { pathname: string | string[] }) =>
            location.pathname.includes(topMenu),
          isExternalLink: imInManager,
        };
      }) || [],
    [imInManager, query.data]
  );

  const managerRouters: TopItem[] = useMemo(
    () => [
      {
        title: isAdmin || isOwner ? t('Clases') : t('Mis clases'),
        linkTo: '/manager/classrooms',
        inlineManualData: 'classrooms',
        isExternalLink: !imInManager,
        show:
          platformEnabled && (isTeacher || isAdmin || isOwner) && !isInWebview,
      },
      {
        title: t('Centro'),
        linkTo: '/manager/school/teachers',
        isExternalLink: !imInManager,
        show:
          (isAdmin || isOwner || isAdvisor || isDistrictAdmin) && !isInWebview,
        inlineManualData: 'school',
      },
    ],
    [
      imInManager,
      isAdmin,
      isAdvisor,
      isDistrictAdmin,
      isInWebview,
      isOwner,
      isTeacher,
      platformEnabled,
      t,
    ]
  );

  const getTopItems = (): TopItem[] => {
    if (!query.isSuccess) return [];
    const allRoutes = [...sitemapRoutes, ...managerRouters];
    if (allRoutes.filter((route) => route.show).length === 1) return [];
    return allRoutes;
  };

  const topItems = useMemo(getTopItems, [
    query.isSuccess,
    sitemapRoutes,
    managerRouters,
  ]);

  const filterByWebviewItems = useCallback(
    (item: SitemapItem) => {
      const isAppletsSelector =
        item.linkItemType === 'EXTERNAL' && item.url?.includes('/applets');

      if (isAppletsSelector && isInWebview) {
        return false;
      }

      return true;
    },
    [isInWebview]
  );

  const sitemaps = useMemo(
    () =>
      query.data?.map((sitemap) => ({
        ...sitemap,
        items: sitemap.items.filter(filterByWebviewItems),
      })),
    [filterByWebviewItems, query.data]
  );

  return { topItems, sitemaps, ...query };
}
