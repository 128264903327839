import { AuthProvider, Credentials } from '@innovamat/social-login';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { lazy, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import App from './components/App';
import { queryClient } from './config/queryClient';
import ThemeWrapper from './theme/ThemeWrapper';
import init from './utils/init';

import { FlagsProvider } from '@innovamat/flags';
import { EnvVariablesProvider } from '@innovamat/ga-features';
import { SnackBarContainer } from '@innovamat/glimmer-components';
import { GlowApiClientProvider } from '@innovamat/glow-api-client/provider';
import { useQueryParams } from '@innovamat/hooks';
import { authService, storage } from '@innovamat/radiance-utils';
import { useRegionalConfigVariables } from '@innovamat/regional-config-variables';
import { WebviewBridgeProvider } from '@innovamat/webview-bridge';
import { SessionDoneWrapper } from './components/session-done-wrapper';
import ThirdPartyConnections from './components/ThirdPartyConnections';
import { getUserAcceptLanguage } from './hooks/use-user/utils';
import useAppLayout from './hooks/useAppLayout';
import { initEnvVariables } from './utils/init-env-variables';

const {
  VITE_GOOGLE_CLIENT_ID,
  VITE_MSAL_CLIENT_ID,
  VITE_USERS_CLIENT_ID,
  VITE_GOOGLE_SECRET,
  VITE_CONFIG_CAT_CACHE_TIME,
  VITE_CONFIG_CAT_KEY,
} = import.meta.env;

const initialCredentials: Credentials = {
  usersClientId: VITE_USERS_CLIENT_ID!,
  apolloUrl: '',
  apiUsersUrl: '',
  msalClientId: VITE_MSAL_CLIENT_ID!,
  googleClientId: VITE_GOOGLE_CLIENT_ID!,
  googleSecretId: VITE_GOOGLE_SECRET!,
};

const Manager = lazy(() =>
  import('@innovamat/manager').then((module) => ({
    default: module.Manager,
  }))
);

function AppRoot() {
  const query = useQueryParams();

  useAppLayout();

  const getAutoLogin = () => {
    const autologin = query.get('autologin');

    return {
      google: autologin === 'google',
      msal: autologin === 'msal',
    };
  };

  const getAuthToken = (): string => {
    const token = storage.tokenInfo.get()?.access_token;

    if (token) {
      return `Bearer ${token}`;
    }

    return '';
  };

  const [credentials, setCredentials] =
    useState<Credentials>(initialCredentials);

  const regionalConfigVariables = useRegionalConfigVariables();

  useEffect(() => {
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      apolloUrl: regionalConfigVariables.apolloServer,
      apiUsersUrl: regionalConfigVariables.apiUsers,
    }));
  }, [regionalConfigVariables]);

  if (credentials.apolloUrl === '' || credentials.apiUsersUrl === '') {
    return <></>;
  }

  init(credentials.apiUsersUrl);

  return (
    <EnvVariablesProvider
      envVariables={initEnvVariables(regionalConfigVariables)}
    >
      <FlagsProvider
        sdkKey={VITE_CONFIG_CAT_KEY}
        cacheTime={VITE_CONFIG_CAT_CACHE_TIME}
      >
        <WebviewBridgeProvider>
          <GlowApiClientProvider
            client={queryClient}
            apiUrl={regionalConfigVariables.apolloServer}
            getAuthToken={() => getAuthToken()}
            getUserAcceptLanguage={() => getUserAcceptLanguage()}
            onRefreshToken={async () => {
              await authService.refreshToken();
            }}
            onRequestError={(error) => {
              if (error === 'UNAUTHENTICATED') {
                storage.clear();
                window.location.href = '/';
              }
            }}
          >
            <ThirdPartyConnections>
              <AuthProvider
                credentials={credentials}
                autologin={getAutoLogin()}
              >
                <ThemeWrapper>
                  <Switch>
                    <Route path={'/manager'}>
                      <Manager />
                    </Route>
                    <Route>
                      <SessionDoneWrapper>
                        <App />
                      </SessionDoneWrapper>
                    </Route>
                  </Switch>
                  <SnackBarContainer autoClose={3000} closeButton={false} />
                  <ReactQueryDevtools initialIsOpen={false} />
                </ThemeWrapper>
              </AuthProvider>
            </ThirdPartyConnections>
          </GlowApiClientProvider>
        </WebviewBridgeProvider>
      </FlagsProvider>
    </EnvVariablesProvider>
  );
}

export default withTranslation()(AppRoot);
