import { useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { PERMISSIONS, SitemapItem } from './sitemap.d';
import { getTopMenu } from './utils';
import { useSitemap } from './use-sitemap';

export function useSitemapItems() {
  const { sitemaps, isSuccess, isError } = useSitemap();
  const { pathname } = useLocation();
  const topMenu = getTopMenu(pathname);

  const sitemapByTopMenu = useMemo(
    () => sitemaps?.find((sitemap) => sitemap.route.includes(topMenu)),
    [sitemaps, topMenu]
  );

  const getPath = (): string => {
    const path = window.location.pathname;
    const page = path.split('/')[1];
    return page;
  };

  const pathExists = (): boolean => {
    const page = getPath();
    if (!sitemaps) return false;
    for (const sitemap of sitemaps) {
      if (page === sitemap.route.split('/')[1]) return true;
    }
    return false;
  };

  const getSitemapByPath = useCallback(() => {
    const page = getPath();

    const sitemap = sitemaps?.find((sitemap) => sitemap.route.includes(page));
    return sitemap;
  }, [sitemaps]);

  const getTypeOfPagePermission = (item: SitemapItem): string =>
    item.fullPageId ? PERMISSIONS.fullAccess : PERMISSIONS.demoAccess;

  const getPageFromSitemap = useCallback(() => {
    if (!sitemaps) return null;
    const path = window.location.pathname;
    const sitemap = getSitemapByPath();

    const menuItem = sitemap?.items
      .filter((item) => item.status === 'ACTIVE')
      .find((item) => item.urlName?.includes(path));

    return menuItem || null;
  }, [getSitemapByPath, sitemaps]);

  const getPageIdFromSitemap = useCallback(() => {
    const menuItem = getPageFromSitemap();
    if (!menuItem) return null;

    return menuItem.fullPageId || menuItem.demoPageId;
  }, [getPageFromSitemap]);

  return {
    topMenu,
    sitemaps,
    sitemapByTopMenu,
    getPageFromSitemap,
    getSitemapByPath,
    getPageIdFromSitemap,
    getTypeOfPagePermission,
    pathExists,
    isSuccess,
    isError,
  };
}
