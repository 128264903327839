export type GetSitemapBody = {
  region: string | undefined;
  courseOrder: number | undefined;
  level: string | undefined;
  viewAs: 'student' | undefined;
};

export type Sitemap = {
  icon: number;
  items: SitemapItem[];
  route: string;
  value: string;
};

export type SitemapItem = {
  demoPageId: string | null;
  fullPageId: string | null;
  icon: number;
  linkItemType: 'PAGE_ID' | 'ROUTE' | 'EXTERNAL';
  route: string | null;
  status: 'ACTIVE' | 'INACTIVE' | 'DISABLED';
  type: 'group' | 'link';
  url: string | null;
  urlName: string | null;
  value: string | null;
  program: 'LABORATORY' | 'APP' | 'ADVENTURE' | null;
  isOnlyForClassroom: boolean;
};

export const PERMISSIONS = {
  fullAccess: 'full',
  demoAccess: 'demo',
};

export type TopItem = {
  title: string;
  linkTo: string;
  inlineManualData: string;
  isExternalLink: boolean;
  show: boolean;
};
