import styled from 'styled-components';

const ClassroomsDropdown = styled.div`
  margin: 10px 0;
`;

const ClassroomsDropdownItem = styled.div`
  margin: 4px 0px;
  cursor: pointer;
  padding: 4px 16px;
  div {
    background-color: transparent;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.stage['02']}1A;
  }
`;

export { ClassroomsDropdown, ClassroomsDropdownItem };
