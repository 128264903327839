import styled from 'styled-components';
import SimpleLoader from '../Loaders/SimpleLoader';
import CircleLoader from '../Loaders/circle-loader';

const TitleLoader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const SkeletonWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export default function CourseDropdownSkeleton() {
  return (
    <SkeletonWrapper>
      <CircleLoader height={40} width={40} />
      <TitleLoader>
        <SimpleLoader width={120} height={20} />
        <SimpleLoader width={50} height={14} />
      </TitleLoader>
    </SkeletonWrapper>
  );
}
