export const ARROW_UP = 'arrow-up';
export const ARROW_DOWN = 'arrow-down';
export const ARROW_LEFT = 'arrow-left';
export const ARROW_RIGHT = 'arrow-right';
export const LOGO = 'innovamat-logo';
export const ADD = 'single-neutral-actions-add';
export const ALARM_BELL = 'alarm-bell';
export const LOGOUT = 'logout-1';
export const VIEW = 'view';
export const LOVE_IT = 'love-it';
export const SEARCH = 'search';
export const FILTER = 'settings-vertical';
export const HOME = 'house';
export const CHECKLIST = 'checklist';
export const LAB = 'lab-flask-experiment';
export const ADVENTURES = 'maps-mark';
export const APPLETS = 'video-game-xbox-controller';
export const MANIPULATIVE = 'monitor-touch';
export const MANIPULATIVE_BLACK = 'monitor-touch_black';
export const STUDENTS = 'multiple-users';
export const REPORTS = 'analytics-graph-bar';
export const PRINT = 'print-picture';
export const EVALUATE = 'legal-scale-unequal-1';
export const REMOVE = 'remove-square';
export const TALLERS = 'outdoors-landscape';
export const RACONS = 'educative-toys-maths';
export const AMBIENTS = 'board-game-jenga';
export const RUTINES = 'calendar-date';
export const SABERMES = 'monitor-heart-rate-up';
export const CAPUSULES = 'pill';
export const WEBINARS = 'e-learning-monitor';
export const H1 = 'paginate-filter-1';
export const H2 = 'paginate-filter-2';
export const H3 = 'paginate-filter-3';
export const H4 = 'paginate-filter-4';
export const H5 = 'paginate-filter-5';
export const H6 = 'paginate-filter-6';
export const H7 = 'paginate-filter-7';
export const H8 = 'paginate-filter-8';
export const H9 = 'paginate-filter-9';
export const H10 = 'paginate-filter-10';
export const VIDEO = 'social-video-youtube-clip';
export const LEARNING_MONITOR = 'e-learning-monitor';
export const VIDEO_GAME = 'video-game-xbox-controller';
export const CAMERA = 'meeting-camera';
export const CHECK_LIST = 'checklist';
export const CONTROLS_NEXT = 'controls-next';
export const CONTROLS_PAUSE = 'controls-pause';
export const CONTROLS_PLAY = 'controls-play';
export const CONTROLS_PREVIOUS = 'controls-previous';
export const CONTROLS_RECORD = 'controls-record';
export const CONTROLS_REWIND = 'controls-rewind';
export const CONTROLS_STOP = 'controls-stop';
export const EXPAND = 'expand';
export const SHRINK = 'shrink';
export const VOLUME_FULL = 'volume-control-full';
export const VOLUME_LOW = 'volume-control-low';
export const VOLUME_MEDIUM = 'volume-control-medium';
export const VOLUME_OFF = 'volume-control-off';
export const SETTINGS = 'cog';
export const HELP = 'question-circle';
export const CIRCLEPLAY = 'circle-play';
export const DIRECTIONARROWS = 'direction-button-arrows';
export const ZOOMINPAGE = 'zoom-in-page';
export const ZOOMIN = 'zoom-in';
export const ZOOMOUTPAGE = 'zoom-out-page';
export const ZOOMOUT = 'zoom-out';
export const DOUBLE_PAGE = 'double_page';
export const SINGLE_PAGE = 'single_page';
export const EXPAND_2 = 'expand-5';
export const SHRINK_2 = 'shrink-4';
export const CLOCK = 'time-stopwatch';
export const CLOSE = 'close';
export const CHESS = 'chess-knight';
export const EDU_TOY = 'educative-toys-counting';
export const EDU_TOY_2 = 'educative-toys-counting-1';
export const ROCKET = 'startup-launch-1-1';
export const BOARD = 'school-board-maths-1';
export const LOCATION = 'pin-location-1';
export const GEOMETRY = 'board-game-geometry';
export const GEOGEBRA = 'geogebra';
export const GAME_CONTROLLER = 'video-game-controller-person';
export const INNOVAPPDEMO = 'tablet-touch-1';
export const COPY_CLIPBOARD = 'copy-clipboard';
export const WHATSAPP = 'whatsapp';
export const DOWNLOAD_ZIP = 'file-zip-1';
export const DOWNLOAD_XLS = 'office-file-xls';
export const DOWNLOAD_PDF = 'office-file-pdf';
export const DOWNLOAD_ALL = 'drawer-download';
export const DOWNLOAD_DEFAULT = 'office-file-text-graph-alternate';
export const ASSESSOR = 'messages-people-person-bubble-square-1';
export const INTERACTIVE_GUIDE = 'streamline-icon-study-exam-math32x32';
export const PLAY_SESSION = 'streamline-icon-video-player-movie-132x32';
export const RELATED_CONTENT = 'streamline-icon-archive-drawer32x32';
