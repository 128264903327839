import { storage } from '@innovamat/radiance-utils';
import { useEffect } from 'react';
import i18n from '../config/i18n';
import { useUser } from './use-user';
import useLanguage from './useLanguage';

export default function useAuthentication() {
  const { userId, language } = useUser();
  const hasToken = Boolean(storage.tokenInfo.get());
  const { setLanguageFromUrl, setHtmlLanguage } = useLanguage();

  useEffect(() => {
    if (userId && language) {
      i18n.changeLanguage(language);
      setHtmlLanguage(language);
    }
  }, [language, userId]);

  useEffect(() => {
    if (!hasToken) {
      setLanguageFromUrl();
    }
  }, [hasToken]);
}
