type laguageKeys = {
  [index: string]: {
    [index: string]: string;
  };
};

const Routes: laguageKeys = {
  es: {
    '/innovamat': '//innovamat.com',
    '/termsAndConditions':
      '//innovamat.com/condiciones-generales-de-contratacion/',
    '/dataPolicy': '//innovamat.com/politica-de-privacidad/',
    '/cookiesPolicy': '//innovamat.com/politica-cookies/',
    '/downloadInnovamatDesktop':
      '//innovamat.com/descarga-plataforma-innovamat/',
    '/downloadInnovamatApple':
      '//apps.apple.com/es/app/innovamat-matem%C3%A1ticas-escuela/id1512153412',
    '/downloadInnovamatPlayStore':
      '//play.google.com/store/apps/details?id=com.innovamat.innovamat&hl=es',
  },
  ca: {
    '/innovamat': '//innovamat.com',
    '/termsAndConditions':
      '//innovamat.com/ca/condiciones-generales-de-contratacion/',
    '/dataPolicy': '//innovamat.com/ca/politica-de-privacidad/',
    '/cookiesPolicy': '//innovamat.com/ca/politica-cookies/',
    '/downloadInnovamatDesktop':
      '//innovamat.com/ca/descarga-plataforma-innovamat/',
    '/downloadInnovamatApple':
      '//apps.apple.com/es/app/innovamat-matem%C3%A1ticas-escuela/id1512153412',
    '/downloadInnovamatPlayStore':
      '//play.google.com/store/apps/details?id=com.innovamat.innovamat&hl=ca',
  },
  en: {
    '/innovamat': '//innovamat.com',
    '/termsAndConditions':
      '//innovamat.com/en/condiciones-generales-de-contratacion/',
    '/dataPolicy': '//innovamat.com/en/politica-de-privacidad/',
    '/cookiesPolicy': '//innovamat.com/en/politica-cookies/',
    '/downloadInnovamatDesktop':
      '//innovamat.com/en/descarga-plataforma-innovamat/',
    '/downloadInnovamatApple':
      '//apps.apple.com/app/innovamat-matem%C3%A1ticas-escuela/id1512153412',
    '/downloadInnovamatPlayStore':
      '//play.google.com/store/apps/details?id=com.innovamat.innovamat&hl=en',
  },
  eu: {
    '/innovamat': '//innovamat.com',
    '/termsAndConditions':
      '//innovamat.com/eu/condiciones-generales-de-contratacion/',
    '/dataPolicy': '//innovamat.com/eu/politica-de-privacidad/',
    '/cookiesPolicy': '//innovamat.com/eu/politica-cookies/',
    '/downloadInnovamatDesktop':
      '//innovamat.com/descarga-plataforma-innovamat/',
    '/downloadInnovamatApple':
      '//apps.apple.com/es/app/innovamat-matem%C3%A1ticas-escuela/id1512153412',
    '/downloadInnovamatPlayStore':
      '//play.google.com/store/apps/details?id=com.innovamat.innovamat&hl=eu',
  },
};

export default Routes;
