import { CLOSE } from '../../assets/icons/icons';
import Icon from '../Icon';
import { IconContainer, Wrapper } from './TopBanner.styled';

type BannerProps = {
  closeBanner: () => void;
  className?: string;
  title?: string;
  subtitle?: string;
};

const TopBanner = ({
  closeBanner,
  className,
  title,
  subtitle,
}: BannerProps) => (
  <Wrapper className={className}>
    {title && <div>{title}</div>}
    {subtitle && <div>{subtitle}</div>}
    <IconContainer onClick={closeBanner}>
      <Icon icon={CLOSE} />
    </IconContainer>
  </Wrapper>
);

export default TopBanner;
