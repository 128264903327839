import styled, { css } from 'styled-components';
import { burgerMenuBreakpoint } from '../HomeLayout/HomeLayout.styled';

const RelativesContainer = styled.div`
  position: relative;
`;

const Container = styled.div<{ isDropdownOpen?: boolean }>`
  position: absolute;
  width: 100%;
  z-index: 50;
  ${({ isDropdownOpen, theme }) =>
    isDropdownOpen &&
    css`
      background-color: ${theme.colors.white};
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
      @media screen and (max-width: ${burgerMenuBreakpoint}) {
        position: fixed !important;
        width: 220px !important;
      }
    `}
`;

const ButtonContainer = styled.div<{ isDisabled: boolean }>`
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
  cursor: pointer;
  background: none;
  width: 100%;
  border: none;
  align-items: center;
  padding: 8px 0px 8px 12px !important;
  > div > div > div {
    :first-of-type {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
    `}
`;

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;

  max-height: calc(calc(var(--vh, 1vh) * 100) - 170px);
  overflow-y: auto;
`;

export { RelativesContainer, Container, ButtonContainer, DropdownContainer };
