import { Assessor, authService } from '@innovamat/radiance-utils';
import { useQuery } from '@tanstack/react-query';
import { useUser } from '../use-user';

export default function useAssessor() {
  const { crmId, organizationId } = useUser();

  return useQuery<Assessor>({
    queryKey: ['assessor'],
    queryFn: () => authService.getAssessorIntegromat(crmId!, organizationId!),
    enabled: false,
  });
}
