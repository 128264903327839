import { useState } from 'react';
import { Avatar as MenuButton, Icon } from '@innovamat/innova-components';

import MenuItem from '../MenuItem';
import MenuSection from '../MenuSection';

import CourseDropdown from '../CourseDropdown';

import { getAvatarByClassroom } from '@innovamat/glimmer-assets';
import {
  AvatarsContainer,
  FixedContent,
  List,
  SidebarDrawer,
  Wrapper,
} from './MainMenu.styled';
import type { SitemapItem } from '../../hooks/use-sitemap/sitemap';
import { useUser } from '../../hooks/use-user';
import { useSitemapItems } from '../../hooks/use-sitemap/use-sitemap-items';
import { useCollapseSidebar } from '../../hooks/use-collapse-sidebar';
import { Avatar, AvatarStage } from '@innovamat/glimmer-components';
import { useCourses } from '../../hooks/use-courses';
import { useClassrooms } from '../../hooks/use-classrooms';

const MainMenu = (): JSX.Element => {
  useClassrooms();
  const { courses } = useCourses();
  const { currentClassroom, courseOrder } = useUser();
  const { sitemapByTopMenu } = useSitemapItems();
  const { isCollapsed } = useCollapseSidebar();

  const [openDrawer, setOpenDrawer] = useState(false);

  const courseSelected = courses?.find((c) => c.order === courseOrder);

  const getMenuItemType = (
    item: SitemapItem,
    index: number
  ): JSX.Element | null => {
    const key = item.value + item.urlName! + index;
    if (item.type === 'group') {
      return <MenuSection label={item.value!} key={key} />;
    }
    if (item.status !== 'INACTIVE') {
      return <MenuItem course={courseOrder} item={item} key={key} />;
    }
    return null;
  };

  const menuComponent = (showBtn: boolean): JSX.Element => (
    <Wrapper>
      {showBtn && (
        <Icon
          onClick={() => setOpenDrawer(false)}
          icon="ArrowLeft2"
          data-testid="drawerIconClassroomClose"
          containerClassName="goBackBtn"
        />
      )}
      <FixedContent isCollapsed={isCollapsed}>
        <CourseDropdown />
        <List data-testid="listSiteMap">
          {sitemapByTopMenu?.items
            .filter((item) =>
              item.route === 'reports' ? !!currentClassroom : true
            )
            .filter((item) => currentClassroom || !item?.isOnlyForClassroom)
            .map(getMenuItemType)
            .filter((item) => item !== null)}
        </List>
      </FixedContent>
    </Wrapper>
  );

  if (isCollapsed) {
    return (
      <>
        <div>
          <AvatarsContainer>
            <MenuButton
              className="menu"
              icon="Menu"
              onClick={() => setOpenDrawer(true)}
              shadow
              size={40}
              data-testid="drawerIconClassroomOpen"
            />
            {currentClassroom ? (
              <Avatar img={getAvatarByClassroom(currentClassroom)} />
            ) : (
              <AvatarStage
                courseName={courseSelected?.name || ''}
                courseOrder={courseSelected?.order || 0}
              />
            )}
          </AvatarsContainer>
        </div>
        <SidebarDrawer
          position="left"
          clickOutsideClose
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          {menuComponent(isCollapsed)}
        </SidebarDrawer>
      </>
    );
  }

  return menuComponent(isCollapsed);
};

export default MainMenu;
