import { GoogleClassroomSyncProvider } from '@innovamat/third-party-connections-google';
import { queryClient } from '../../config/queryClient';
import { useUser } from '../../hooks/use-user';
import { useRegionalConfigVariables } from '@innovamat/regional-config-variables';

type Props = {
  children: React.ReactNode;
};

export default function ThirdPartyConnections({ children }: Props) {
  const { isLoggedIn } = useUser();

  const { apiOrganization } = useRegionalConfigVariables();

  return (
    <GoogleClassroomSyncProvider
      client={queryClient}
      organizationsApiUrl={apiOrganization}
      enabled={isLoggedIn}
    >
      {children}
    </GoogleClassroomSyncProvider>
  );
}
