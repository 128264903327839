import styled, { css } from 'styled-components';

const DropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background: none;
  width: 100%;
  border: none;
  align-items: center;
  padding: 18px 28px 18px 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.dark['06']};
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colors.dark['02']};
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
`;

const DropdownContent = styled.div`
  max-height: calc(var(--vh, 1vh) * 33);
  overflow-y: auto;
  padding-bottom: 10px;
  .courseItem {
    gap: 16px;
    cursor: pointer;
    background: none;
    border: none;
    width: 100%;
    padding: 8px 16px;
    ${({ theme }) => css`
      &:hover {
        background-color: ${theme.colors.stage['02']}1A;
      }
    `};
  }
`;

export { DropdownHeader, Title, DropdownContent };
