import { useHistory } from 'react-router-dom';
import { useDisplayBanners } from '../hooks/useDisplayBanners';
import { BannersResponse } from '../types';
import Banner from './Banner';
import { inlineManual } from '@innovamat/radiance-utils';

type Props = {
  urlName: string | null;
  language: string | undefined;
  region: string | undefined;
  roles: string[] | undefined;
  course: number | undefined;
  onSendEvent: (key: string, payload: {}) => void;
} & React.HTMLAttributes<HTMLDivElement>;

export default function BannerContainer({
  urlName,
  language,
  region,
  roles,
  course,
  onSendEvent,
  ...rest
}: Props): JSX.Element | null {
  const history = useHistory();
  const sectionCv = !!urlName?.includes('curriculum/home');
  const sectionDp = !!urlName?.includes('practice/home');
  const sectionTr = !!urlName?.includes('learning/home');

  const { banners, onCloseBanner } = useDisplayBanners({
    language,
    region,
    roles,
    course,
  });

  const handleCloseBanner = (): void => {
    handleBtnEvent('exit');
    onCloseBanner(banner.id);
  };

  const filterBannersByPath = (bnr: BannersResponse): boolean => {
    return (
      (bnr.sectionCv && sectionCv) ||
      (bnr.sectionDp && sectionDp) ||
      (bnr.sectionTr && sectionTr)
    );
  };

  const handleBtnEvent = (action: string): void => {
    onSendEvent('banner_click', {
      banner_name: banner.title,
      banner_id: banner.id,
      action,
    });
  };

  const handleBtnClick = (): void => {
    handleBtnEvent('cta');

    if (banner.inlineTopicId) {
      inlineManual.activateTopic(banner.inlineTopicId);
    } else if (banner.ctaExternal) {
      window.open(banner.ctaLink, '_blank');
    } else {
      history.push(`${banner.ctaLink}`);
    }

    if (banner.canBeClosed) {
      onCloseBanner(banner.id);
    }
  };

  const bannersToDisplay = banners.filter(filterBannersByPath);

  if (bannersToDisplay.length === 0) return null;

  const banner = bannersToDisplay[0];

  return (
    <Banner
      btnText={banner.ctaText}
      colour={banner.colour}
      imgAlt={banner.icon.name}
      imgSrc={banner.icon.url}
      showBtn={banner.cta}
      text={banner.text}
      title={banner.title}
      onBtnClick={handleBtnClick}
      onBtnCloseClick={handleCloseBanner}
      canBeClosed={banner.canBeClosed}
      {...rest}
    />
  );
}
