import { Course } from '@innovamat/radiance-utils';
import { apolloFetch } from '../../utils/apolloFetch';

export const getCourses = async (regionCode: string): Promise<Course[]> => {
  const graphQl = JSON.stringify({
    query: `query Courses($regionCode: String!, $organizationId: ID, $activeLevel: String) {
      courses(regionCode: $regionCode, organizationId: $organizationId, activeLevel: $activeLevel) {
        id
        region
        order
        name
      }
    }`,
    variables: { regionCode, activeLevel: 'DEFAULT' },
  });
  const response = await apolloFetch({
    graphQl,
  });
  return response.data?.courses;
};
