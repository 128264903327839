import ContentLoader from 'react-content-loader';

const ProfileLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width={240}
    height={127}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="20" cy="60" r="20" />
    <rect x="0" y="16" rx="4" ry="4" width="126" height="16" />
    <rect x="56" y="46" rx="4" ry="4" width="140" height="16" />
    <rect x="56" y="68" rx="4" ry="4" width="140" height="8" />
    <rect x="56" y="100" rx="4" ry="4" width="140" height="8" />
  </ContentLoader>
);

export default ProfileLoader;
