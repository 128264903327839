import { authAxiosInstance } from '@innovamat/ga-features';

type AxiosRequestHeaders = {
  [x: string]: string | number | boolean;
};

type ApolloParams = {
  graphQl: string;
  headers?: AxiosRequestHeaders;
};

type ApolloFetch = ({ graphQl, headers }: ApolloParams) => Promise<any>;

export const apolloFetch: ApolloFetch = async ({ graphQl, headers }) => {
  const response = await authAxiosInstance({
    url: `${window.__GA_CONFIG__.apolloServer}`,
    method: 'POST',
    data: JSON.parse(graphQl),
    headers,
  });
  return response.data;
};
