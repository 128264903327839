import { storage } from '@innovamat/radiance-utils';
import {
  RegionalConfigVariablesProvider,
  isUSRegion,
} from '@innovamat/regional-config-variables';
import * as ReactDOM from 'react-dom/client';
import { Router } from 'react-router-dom';
import AppRoot from './AppRoot';
import history from './utils/history';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const environment = import.meta.env.VITE_ENVIRONMENT || 'local';
const fastApiUrl = import.meta.env.VITE_NEXT_GEOLOCATION_URL || '';
const region = (await isUSRegion(fastApiUrl)) ? 'us' : 'nonUs';

storage.initRedirectIfTokenIsInvalid({ redirectUrl: '/' });

root.render(
  <Router history={history}>
    <RegionalConfigVariablesProvider environment={environment} region={region}>
      <AppRoot />
    </RegionalConfigVariablesProvider>
  </Router>
);
