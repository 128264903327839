import { Classroom, Course, storage } from '@innovamat/radiance-utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getClassrooms } from './api';
import {
  getDefaultClassroom,
  sortClassrooms,
} from '../../utils/classroomsAndCourses';
import { User, useUser } from '../use-user';
import { useCourses } from '../use-courses';
import { useEffect } from 'react';

export function useClassrooms() {
  const { organizationId, isRole, currentClassroom, region, currentCourse } =
    useUser();
  const { courses, setDefaultCourse } = useCourses();
  const queryClient = useQueryClient();
  const classroomsQuery = useQuery<Classroom[] | undefined>({
    queryKey: ['classrooms'],
    queryFn: async () => {
      if (organizationId === undefined) return;
      const classResponse = await getClassrooms(organizationId);
      const sortedClassrooms = sortClassrooms(classResponse);
      onClassroomInUser(sortedClassrooms);
      return sortedClassrooms;
    },
    staleTime: Infinity,
    enabled: Boolean(isRole.Teacher && organizationId),
  });

  const onClassroomInUser = (classrooms: Classroom[] | undefined): void => {
    const defaultOrCurrentClassroom =
      !currentClassroom ||
      !classrooms?.some((c) => c.id === currentClassroom.id)
        ? getDefaultClassroom(classrooms)
        : classrooms?.find((c) => c.id === currentClassroom.id);
    if (!defaultOrCurrentClassroom) return;
    storage.classroom.set(defaultOrCurrentClassroom);
    const course: Course = {
      id: defaultOrCurrentClassroom.courseId,
      name: defaultOrCurrentClassroom.courseName,
      order: defaultOrCurrentClassroom.courseOrder,
      region: region || '',
    };
    storage.course.set(course);
    queryClient.setQueryData<User | undefined>(['user'], (oldData) => {
      if (!oldData) return oldData;
      return {
        ...oldData,
        navigation: {
          ...oldData.navigation,
          currentClassroom: defaultOrCurrentClassroom,
          currentCourse: course,
        },
      };
    });
  };

  useEffect(() => {
    if (
      classroomsQuery.isSuccess &&
      classroomsQuery.data?.length === 0 &&
      courses &&
      courses?.length > 0 &&
      !currentCourse
    ) {
      setDefaultCourse(courses);
    }
  }, [
    courses,
    classroomsQuery.data,
    classroomsQuery.isSuccess,
    currentCourse,
    setDefaultCourse,
  ]);

  return {
    ...classroomsQuery,
    classrooms: classroomsQuery.data,
  };
}
