import { useEffect, useRef, useState } from 'react';
import { Icon } from '@innovamat/innova-components';
import { stages, Classroom, Course } from '@innovamat/radiance-utils';

import { useOnClickOutside } from '@innovamat/hooks';

import { setEventData } from '../../utils/setEventData';

import Courses from './Courses';
import MyClassrooms from './MyClassrooms';

import { getAvatarByClassroom } from '@innovamat/glimmer-assets';
import {
  setInlineManualCourse,
  setTeacherWithNoClass,
} from '../../utils/inlineManual';
import {
  ButtonContainer,
  Container,
  DropdownContainer,
  RelativesContainer,
} from './CourseDropdown.styled';
import { useUser } from '../../hooks/use-user';
import CourseDropdownSkeleton from './course-dropwdown-sekeleton';
import { AvatarCard } from '@innovamat/glimmer-components';
import { useClassrooms } from '../../hooks/use-classrooms';
import { useCourses } from '../../hooks/use-courses';

function CourseDropdown() {
  const { classrooms } = useClassrooms();
  const { courses } = useCourses();

  const { currentClassroom, currentCourse, organization, isRole } = useUser();

  const platformEnabled = organization?.platformEnabled!;

  const [openDropdown, setOpenDropdown] = useState(false);

  const ref = useRef(null);
  const isStudent = isRole.Student;
  const isTeacher = isRole.Teacher;

  const { onChangeClassroom, onChangeCourse } = useUser();

  const filterByStudentRole = (course: Course): boolean =>
    isStudent ? !!stages.isSecundaria(course.order) : !!course;

  const coursesList = courses?.filter(filterByStudentRole) || [];

  useEffect(() => {
    setTeacherWithNoClass(platformEnabled, classrooms);
  }, [platformEnabled, classrooms]);

  useEffect(() => {
    if (currentCourse) {
      setInlineManualCourse(currentCourse.order);
    }
  }, [currentCourse]);

  const handleOpenDropdown = (): void => {
    if (!isStudent) {
      setOpenDropdown((prevState) => !prevState);
    }
  };

  useOnClickOutside(ref, () => {
    if (openDropdown) {
      setOpenDropdown(false);
    }
  });

  const handleSelectCourse = (course: Course): void => {
    onChangeCourse(course);
    setOpenDropdown(false);
    setEventData('change_course', {
      from_course: course?.order,
    });
  };

  const handleSelectClassroom = (classroom: Classroom): void => {
    onChangeClassroom(classroom);
    setOpenDropdown(false);
    setEventData('change_course', {
      from_course: classroom.courseOrder,
    });
  };

  const showMyClassrooms =
    platformEnabled && isTeacher && classrooms && !!classrooms?.length;

  const showMyCourses =
    !showMyClassrooms || !classrooms || classrooms.length <= 2;

  const isLoading = !coursesList?.length && !classrooms?.length;

  const getButtonContent = (): JSX.Element => {
    if (isLoading) return <CourseDropdownSkeleton />;
    if (currentClassroom) {
      return (
        <AvatarCard>
          <AvatarCard.Avatar img={getAvatarByClassroom(currentClassroom)} />
          <AvatarCard.Text subtitle={currentClassroom.courseName}>
            {currentClassroom?.name}
          </AvatarCard.Text>
          {!isStudent && !isLoading && <Icon icon="ArrowDown" size="sm" />}
        </AvatarCard>
      );
    }

    return (
      <AvatarCard>
        <AvatarCard.AvatarStage
          courseName={currentCourse?.name || ''}
          courseOrder={currentCourse?.order || 0}
        />
        <AvatarCard.Text subtitle="">{currentCourse?.name}</AvatarCard.Text>
        {!isStudent && !isLoading && <Icon icon="ArrowDown" size="sm" />}
      </AvatarCard>
    );
  };

  return (
    <RelativesContainer>
      <Container
        ref={ref}
        data-inlinemanual="courseselector"
        isDropdownOpen={openDropdown}
      >
        {!openDropdown && (
          <ButtonContainer
            isDisabled={isStudent}
            role="presentation"
            onClick={handleOpenDropdown}
            data-testid="courseClassSelectorButton"
          >
            {getButtonContent()}
          </ButtonContainer>
        )}
        {openDropdown && (
          <DropdownContainer>
            {showMyClassrooms && (
              <MyClassrooms
                classrooms={classrooms}
                onSelectClassroom={handleSelectClassroom}
              />
            )}
            <Courses
              coursesList={coursesList}
              selectedCourse={currentCourse?.order || 0}
              onSelectOption={handleSelectCourse}
              defaultOpen={showMyCourses}
            />
          </DropdownContainer>
        )}
      </Container>
    </RelativesContainer>
  );
}

export default CourseDropdown;
