import styled from 'styled-components';

export const HeaderLayout = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
`;

export const EmptyHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 24px;
  height: 72px;
  color: ${({ theme }) => theme.colors.white};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.dark['05']}`};
`;

export const LogoWrapper = styled.div`
  cursor: pointer;
`;

export const Container = styled.div`
  padding: 0px 24px;
  max-width: 922px;
  max-height: 288px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 64px;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
  }
`;

export const FirstColumnWrapper = styled.div`
  display: flex;
`;

export const FirstColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
`;

export const SecondColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 264px;
    height: auto;

    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      width: 156px;
      height: auto;
    }
  }
`;

export const Title = styled.h2`
  width: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    text-align: center;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    text-align: center;
    font-size: 32px;
    line-height: 36px;
  }
`;

export const Description = styled.p`
  width: 100%;
  color: ${({ theme }) => theme.colors.dark['02']};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    text-align: center;
  }
  span {
    text-decoration: underline;
    cursor: pointer;
  }
`;
