import { theme } from '@innovamat/glimmer-components';
import styled, { css } from 'styled-components';

//TODO: move these variables to glimmer theme
const burgerMenuBreakpoint = theme.breakpoints.lg;
const bannerHeight = '43px';
const collapsedMenuWidth = '70px';
const topBarHeight = '72px';
const maxWidth = '1440px';
const sidebarWidth = '240px';
const menuWidth = '238px';

const HeaderWrapper = styled.div<{ showBanner?: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  height: 72px;
  ${({ showBanner }) =>
    showBanner &&
    css`
      height: calc(70px + ${bannerHeight});
    `}
  .topBanner {
    top: ${bannerHeight};
    @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
      top: 0;
    }
  }
`;

const Main = styled.main`
  display: flex;
`;

const MainMenuContainer = styled.div<{
  showBanner?: boolean;
  isCollapsed: boolean;
}>`
  height: calc(calc(var(--vh, 1vh) * 100) - 96px);
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;
  margin: 12px 0px;
  margin-left: 12px;
  min-width: 240px;
  width: 240px;
  z-index: 10;
  ${({ showBanner }) =>
    showBanner &&
    css`
      height: calc(var(--realVh) - (84px + ${bannerHeight})) !important;
      @media screen and (max-width: 720px) {
        height: calc(calc(var(--vh, 1vh) * 100) - 84px);
      }
    `}

  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      min-width: 70px;
      width: ${collapsedMenuWidth};
      margin-left: 0;
      padding-top: 20px;
    `}
`;

const ContentArea = styled.div`
  overflow: auto;
  height: calc(calc(var(--vh, 1vh) * 100) - ${topBarHeight});
  background-color: white;
  padding: 32px 0px;
  width: 100%;

  @media screen and (max-width: ${burgerMenuBreakpoint}) {
    padding-left: 0px;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding-left: 12px;
  }
`;

export {
  HeaderWrapper,
  Main,
  MainMenuContainer,
  ContentArea,
  bannerHeight,
  burgerMenuBreakpoint,
  collapsedMenuWidth,
  topBarHeight,
  maxWidth,
  sidebarWidth,
  menuWidth,
};
