import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { setFromPage } from '@innovamat/radiance-utils';
import { useSitemapItems } from './use-sitemap/use-sitemap-items';
import { useCallback } from 'react';

// I haven't had time neither strenght enough to move this hook,
// but it certainly has to dissapear at some point,
// because this functionality it's already implemented in the resource-navigation library provider
export default function useResourceNavigation() {
  const history = useHistory();
  const { t } = useTranslation();
  const { topMenu } = useSitemapItems();

  const constructSessionURL = useCallback(
    (
      type: string,
      resourceId: string,
      fromList: string | null,
      fromPage: string | null,
      referalInfo?: any,
      hasDigitalGuide?: boolean,
      disabledDigitalGuide?: boolean
    ) => {
      const origin = window.location?.origin;
      const typePath =
        topMenu + `/${type}/:id`.replace(':id', encodeURIComponent(resourceId));

      if (!origin) {
        return typePath;
      }

      const typeURL = new URL(typePath, origin);

      if (type === 'session') {
        typeURL.searchParams.append(
          'challengeId',
          encodeURIComponent(referalInfo?.challengeId ?? '')
        );
      }

      typeURL.searchParams.append(
        'fromList',
        encodeURIComponent(referalInfo?.list_id ?? fromList ?? '')
      );

      if (referalInfo.list_type)
        typeURL.searchParams.append('listType', referalInfo.list_type);

      if (!disabledDigitalGuide) {
        typeURL.searchParams.append(
          'fromPage',
          encodeURIComponent(referalInfo?.page_id ?? fromPage ?? '')
        );
      } else {
        typeURL.searchParams.append('fromPage', '');
      }

      if (hasDigitalGuide) {
        typeURL.searchParams.append(
          'hasDigitalGuide',
          encodeURIComponent(hasDigitalGuide.toString())
        );
      }

      const path = type + typeURL.href.toString().split(type)[1];

      return path;
    },
    [topMenu]
  );

  const goToResource = useCallback(
    (
      type: string,
      resourceId: string,
      referalInfo?: any,
      hasDigitalGuide?: boolean,
      disabledDigitalGuide?: boolean
    ) => {
      let path;
      const url = new URL(window.location.href);
      const fromList = url.searchParams.get('fromList');
      const fromPage = url.searchParams.get('fromPage');

      setFromPage();

      const urlTypes = {
        session: 'session',
        genially: 'doc',
        pdf: 'pdf',
        vimeo: 'video',
        applet: 'applet',
        geogebra: 'geogebra',
        manipulative: 'applet',
      };

      path = constructSessionURL(
        urlTypes[type],
        resourceId,
        fromList,
        fromPage,
        referalInfo,
        hasDigitalGuide,
        disabledDigitalGuide
      );

      if (path) {
        path = `/${topMenu}/${path}`;
        history.push(path);
      }
    },
    [constructSessionURL, history, t, topMenu]
  );

  const getPathToResource = (type: string, resourceId: string) => {
    const origin = window.location?.origin;
    const typePath =
      topMenu + `/${type}/:id`.replace(':id', encodeURIComponent(resourceId));

    if (!origin) {
      return typePath;
    }

    const typeURL = new URL(typePath, origin);

    return typeURL.href.toString();
  };

  return { goToResource, constructSessionURL, getPathToResource };
}
