import { eventLogging } from '@innovamat/event-logging';
import { queryClient } from '../config/queryClient';
import { User } from '../hooks/use-user';

function getCurrentUser(): User | undefined {
  return queryClient.getQueryData<User>(['user']);
}

function getPreviousRoles(): boolean {
  return !!queryClient.getQueryData<User>(['previousRoles']);
}

export const setEventData = (
  eventType: string,
  eventProperties: any = {},
  addUserData = true
) => {
  const payload = { ...eventProperties };
  const isStudentViewActive = !!getPreviousRoles();

  if (addUserData) {
    const user = getCurrentUser();

    if (user) {
      const role = user.roles ? user.roles?.join() : 'Not defined';
      const school_id = user.organizationId
        ? user.organizationId
        : 'Not defined';
      const { organizationName, crmId, language, region, navigation, id } =
        user;
      payload.username = user?.email;
      payload.role = role;
      payload.school_id = school_id;
      payload.school_name = organizationName;
      payload.crm_id = crmId;
      payload.language = language;
      payload.region = region;
      payload.user_uuid = id;

      if (navigation.currentCourse) {
        payload.course = navigation.currentCourse.order;
        payload.classroom_id = navigation.currentClassroom?.id;
      }
    }

    if (isStudentViewActive) {
      payload.in_student_view = true;
    }
  }

  payload.app_version = import.meta.env.VITE_VERSION;

  eventLogging.log(eventType, payload);
};
