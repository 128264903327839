/* eslint-disable */

import styles from './MenuSection.module.scss';

type MenuSectionProps = {
  label: string;
};

const MenuSection = (props: MenuSectionProps) => {
  const { label } = props;

  return (
    <li className={`${styles.sectionName}`}>
      <span className={styles.label}>{label}</span>
    </li>
  );
};

export default MenuSection;
