import { useLocalStorage } from '@innovamat/hooks';
import { BannersFilter } from '../types';
import { languageMapper } from '../utils';
import { useGetBanners } from './useGetBanners';

type Props = {
  language: string | undefined;
  region: string | undefined;
  roles: string[] | undefined;
  course: number | undefined;
};

export function useDisplayBanners({ language, region, roles, course }: Props) {
  const [closedBanners, setClosedBanners] = useLocalStorage<string[]>(
    'closedBanners',
    []
  );

  const getBannerFilters = () => {
    if (!language || !region || !roles || !course) return undefined;
    return {
      locale: languageMapper(language),
      region,
      roles,
      course,
    } as BannersFilter;
  };

  const query = useGetBanners({
    filters: getBannerFilters(),
  });

  const onCloseBanner = (id: string) => {
    const newBanners = [...closedBanners, id];
    setClosedBanners(newBanners);
  };

  const banners =
    query.data
      ?.filter((banner) => !closedBanners.includes(banner.id))
      .sort((a, b) => a.importance - b.importance) || [];

  return { banners, onCloseBanner };
}
