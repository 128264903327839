import { useTranslation } from 'react-i18next';
import DropdownContent from '../DropdownContent';
import { Course } from '@innovamat/radiance-utils';
import { AvatarCard } from '@innovamat/glimmer-components';

type Props = {
  coursesList: Course[];
  selectedCourse: number;
  onSelectOption: (option: Course) => void;
  defaultOpen?: boolean;
};

export default function Courses({
  coursesList,
  selectedCourse,
  onSelectOption,
  defaultOpen,
}: Props) {
  const { t } = useTranslation();
  return (
    <DropdownContent
      title={t('Cursos')}
      defaultOpen={defaultOpen}
      dataInlineManual="mycoursesselector"
      testIdButton="myCoursesSelector"
      testIdDropdown="myCoursesDropdown"
    >
      <>
        {coursesList
          ?.filter((option) => option.order !== selectedCourse)
          .map((option) => (
            <AvatarCard
              className="courseItem"
              key={option.id}
              size="S"
              onClick={() => onSelectOption(option)}
            >
              <AvatarCard.AvatarStage
                courseName={option.name}
                courseOrder={option.order}
              />
              <AvatarCard.Text subtitle="">{option.name}</AvatarCard.Text>
            </AvatarCard>
          ))}
      </>
    </DropdownContent>
  );
}
