import {
  Container,
  Description,
  EmptyHeader,
  FirstColumnContent,
  FirstColumnWrapper,
  HeaderLayout,
  SecondColumnWrapper,
  Title,
} from './E500.styles';
import { useTranslation } from 'react-i18next';
import errorImage from '../../../assets/images/error.png';
import { Logo } from '@innovamat/innova-components';
import { useHistory } from 'react-router-dom';
import { LogoWrapper } from '../Maintenance/Maintenance.styles';
import { ImageComponent, Typography } from '@innovamat/glimmer-components';

export default function E500({ error }: { error: Error }): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation();

  const handleRefresh = (): void => {
    history.push('/');
  };

  console.error(error);

  return (
    <HeaderLayout>
      <EmptyHeader>
        <LogoWrapper onClick={handleRefresh}>
          <Logo />
        </LogoWrapper>
      </EmptyHeader>
      <Container>
        <FirstColumnWrapper>
          <FirstColumnContent>
            <Title>{t('errors.generic.title')}</Title>
            <Description>
              <Description
                dangerouslySetInnerHTML={{
                  __html: t('errors.generic.subtitle', {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
            </Description>
            <Typography.Link1 href="/">
              {t('errors.generic.link')}
            </Typography.Link1>
          </FirstColumnContent>
        </FirstColumnWrapper>
        <SecondColumnWrapper>
          <ImageComponent src={errorImage} alt="500 something went wrong" />
        </SecondColumnWrapper>
      </Container>
    </HeaderLayout>
  );
}
