import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Resource } from '@innovamat/resource-viewer';
import { useSitemapItems } from './use-sitemap/use-sitemap-items';
import { useUser } from './use-user';

export function useGetEventData() {
  const { getPageFromSitemap, getTypeOfPagePermission, topMenu } =
    useSitemapItems();
  const { classroomId } = useUser();
  const queryClient = useQueryClient();
  const menuItem = getPageFromSitemap();

  const pageName = menuItem?.value;
  const pageId = menuItem?.fullPageId || menuItem?.demoPageId;
  const pageType = menuItem && getTypeOfPagePermission(menuItem);

  const { id, type } = useParams<{ id: string; type: string }>();

  const getResourceData = () => {
    if (!id) return null;
    const queryData = queryClient.getQueriesData<Resource>({
      queryKey: ['Resource', { id, classroomId: classroomId || 'self', type }],
    });
    return queryData?.[0][1];
  };

  return {
    id,
    topMenu,
    pageId,
    pageName,
    pageType,
    getResourceData,
  };
}
