import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Topbar } from '@innovamat/ga-components';
import {
  IconButton,
  BorderOverlay,
  Tooltip,
} from '@innovamat/glimmer-components';

import { useTranslation } from 'react-i18next';
import { setEventData } from '../../utils/setEventData';
import NeedHelpModal from '../NeedHelpModal/NeedHelpModal';

import { useFlag } from '@innovamat/flags';
import { NeedHelpButton } from '../NeedHelpButton';
import { LOGOUT_WEBVIEW, useWebviewBridge } from '@innovamat/webview-bridge';

import useStudentViewValues from '../../hooks/useStudentViewValues';
import { Trans } from 'react-i18next';

import styled from 'styled-components';
import { useSitemap } from '../../hooks/use-sitemap';
import { useUser } from '../../hooks/use-user';
import useAssessor from '../../hooks/use-assessor';

import { inlineManual } from '@innovamat/radiance-utils';

const GoBack = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

type HeaderProps = {
  topBarClassName?: string;
};

const Header = ({ topBarClassName }: HeaderProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  const { refetch, data: assessor, isLoading: loadingAssessor } = useAssessor();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    crmId,
    organizationId,
    firstName,
    userId,
    lastName,
    email,
    isRole,
    onLogout,
  } = useUser();

  const isAdminOrOwner = isRole.Admin || isRole.Owner;

  const { topItems } = useSitemap();

  const { isInWebview, sendEventToWebview } = useWebviewBridge();

  const handleGoToSettings = () => {
    history.push('/settings');
  };

  const handleLogout = () => {
    onLogout();
    setEventData('sys_logout');
    inlineManual.deleteInlineManualUser();
    if (isInWebview) {
      setTimeout(() => {
        sendEventToWebview(LOGOUT_WEBVIEW);
      }, 1000);
    }
  };

  const handleGetAssessor = () => {
    if (!assessor) {
      refetch();
    }
  };

  const showHelpButton =
    (isAdminOrOwner ||
      isRole.Teacher ||
      isRole.Advisor ||
      isRole.DistrictAdmin) &&
    !isInWebview;

  const showAssessor = isRole.Teacher || isAdminOrOwner;

  const topBarUser = userId
    ? {
        firstName,
        lastName,
        email,
        school: organizationId,
        crmId,
        assessor,
      }
    : undefined;

  const handleOpenNeedHelpModal = (value: boolean) => {
    setIsModalOpen(value);
    if (value === true) setEventData('ask_a_teacher');
  };

  const { value: showHelpModal } = useFlag('showhelpmodalbyschoolids', false, {
    identifier: '',
    custom: {
      schoolId: organizationId || '',
    },
  });

  const { handleStudentViewToggle, showToggleToStudent, isStudentViewActive } =
    useStudentViewValues();
  const showHelpBtn = isRole.Student && showHelpModal;

  const showFlag = import.meta.env.VITE_ENVIRONMENT === 'staging';
  return (
    <>
      <Topbar
        showFlag={showFlag}
        user={topBarUser as any}
        isStudent={isRole.Student}
        translate={t}
        routes={topItems}
        handleGetAssessor={showAssessor ? handleGetAssessor : undefined}
        handleGoToSettings={!isRole.Student ? handleGoToSettings : undefined}
        handleLogout={handleLogout}
        loadingAssessor={loadingAssessor}
        setLoggingData={setEventData}
        className={topBarClassName}
        showHelpButton={showHelpButton}
        helpWAButton={
          showHelpBtn && !showToggleToStudent ? (
            <NeedHelpButton onClick={() => handleOpenNeedHelpModal(true)} />
          ) : undefined
        }
        studentViewButton={
          showToggleToStudent ? (
            <>
              {isStudentViewActive && (
                <BorderOverlay
                  content={
                    <Trans
                      i18nKey="student_view.overlay"
                      components={{
                        a: (
                          <GoBack
                            onClick={() =>
                              handleStudentViewToggle({
                                buttonLocation: 'overlay',
                              })
                            }
                          />
                        ),
                      }}
                    />
                  }
                />
              )}
              <Tooltip
                content={
                  isStudentViewActive
                    ? t('student_view.button.tooltip.teacher')
                    : t('student_view.button.tooltip.student')
                }
              >
                <IconButton
                  data-inlinemanual="student_view"
                  icon={'StudentViewIcon'}
                  onClick={() =>
                    handleStudentViewToggle({
                      buttonLocation: 'icon',
                    })
                  }
                  state={isStudentViewActive ? 'inUse' : undefined}
                />
              </Tooltip>
            </>
          ) : undefined
        }
      />
      <NeedHelpModal
        isModalOpen={isModalOpen}
        onClose={() => handleOpenNeedHelpModal(false)}
      />
    </>
  );
};

export default Header;
