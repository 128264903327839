import { Classroom } from '@innovamat/radiance-utils';
import { useTranslation } from 'react-i18next';
import DropdownContent from '../DropdownContent';
import { getAvatarByClassroom } from '@innovamat/glimmer-assets';
import { groupedClassrooms } from '../../../utils/classroomsAndCourses';
import {
  ClassroomsDropdown,
  ClassroomsDropdownItem,
} from './MyClassroomsDropdown.styled';
import { AvatarCard } from '@innovamat/glimmer-components';

type Props = {
  classrooms: Classroom[];
  onSelectClassroom: (classroom: Classroom) => void;
};

export type GroupedClassroom = {
  [courseOrder: number]: Classroom[];
};

export default function MyClassrooms({ classrooms, onSelectClassroom }: Props) {
  const { t } = useTranslation();

  const grouped = groupedClassrooms(classrooms);

  return (
    <DropdownContent
      title={t('Mis clases')}
      defaultOpen
      dataInlineManual="myclassroomsselector"
      testIdButton="myClassroomsSelector"
      testIdDropdown="myClassroomsDropdown"
    >
      <>
        {Object.entries(grouped).map(([key, classrooms]) => (
          <div key={key}>
            <ClassroomsDropdown>
              {classrooms.map((classroom) => (
                <ClassroomsDropdownItem key={classroom.id}>
                  <AvatarCard onClick={() => onSelectClassroom(classroom)}>
                    <AvatarCard.Avatar img={getAvatarByClassroom(classroom)} />
                    <AvatarCard.Text subtitle={classroom.courseName}>
                      {classroom.name}
                    </AvatarCard.Text>
                  </AvatarCard>
                </ClassroomsDropdownItem>
              ))}
            </ClassroomsDropdown>
          </div>
        ))}
      </>
    </DropdownContent>
  );
}
