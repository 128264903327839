import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useUser } from '../use-user';
import { getRegionParameters } from './api';

export default function useRegionParameters() {
  const location = useLocation();
  const { educationLevel, region: userRegion } = useUser();

  // Obtener el parámetro 'region' de la URL para el caso de recursos públicos
  const urlSearchParams = new URLSearchParams(location.search);
  const urlRegion = urlSearchParams.get('region');
  if (urlRegion) {
    localStorage.setItem('region', urlRegion);
  }

  const regionCode = urlRegion || userRegion?.replace('-LIT', '');
  const stage = educationLevel === 'SECUNDARIA' ? 'secondary' : 'primary';

  const query = useQuery({
    queryKey: ['region-parameters', regionCode, stage],
    queryFn: () => getRegionParameters(regionCode!, stage),
    enabled: Boolean(regionCode && stage),
  });

  return {
    ...query,
    regionParameters: {
      ...query.data,
      dataOrigin: 'front-web-ga',
    },
  };
}
