export const setFromPage = () => {
  const { pathname } = window.location;
  const fromList = window.location.href.split('?')[1];

  if (fromList) {
    const url = `${pathname}?${fromList}`;
    localStorage.setItem('fromPage', url);
  } else {
    localStorage.setItem('fromPage', pathname);
  }
};

export const getFromPage = () => {
  const from = localStorage.getItem('fromPage');

  const path = window.location.pathname.split('/')[1];
  const url = `/${path}/home`;
  return from ?? url;
};

export const clearFromPage = () => {
  localStorage.removeItem('fromPage');
};
