import { NavLink, Link } from 'react-router-dom';
import { Logo } from '@innovamat/innova-components';
import Account from './Account';
import { Button } from '@innovamat/innova-components';
import { Button as GlimmerButton } from '@innovamat/glimmer-components';
import { Badge } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import { theme } from '@innovamat/innova-components';
import UEFlag from './ue-flag.png';
import { CopyToClipboardButton } from '../CopyToClipboardButton';
import { CLOSE_WEBVIEW, useWebviewBridge } from '@innovamat/webview-bridge';
import { AvatarCard, ImageComponent } from '@innovamat/glimmer-components';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { normalizeImgSource } from '@innovamat/ga-features';

const topBarHeight = '72px';

const TopBar = styled.div`
  height: ${topBarHeight};
  position: fixed;
  width: 100%;
  display: flex;
  z-index: 20;
  justify-content: space-between;
  background-color: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.dark['06']};
  padding: 0 24px;
  box-sizing: border-box;
`;

const StyledBadge = styled(Badge)`
  position: absolute;
  bottom: 4px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 20%;
`;

const NavOptions = styled.div`
  display: flex;
  align-items: center;

  a {
    color: ${theme.colors.black};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-bottom: 4px solid transparent;
    text-decoration: none;
    padding: 0 20px;
    position: relative;

    &.topbarSelectedLink {
      color: ${theme.colors.brand.dark['02']};
      border-bottom: 4px solid ${theme.colors.brand.dark['02']};
    }
  }
`;

const UserConfig = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  width: 20%;
  justify-content: flex-end;
`;

const HelpButton = styled(Button)`
  &:hover {
    background-color: transparent !important;
    color: ${theme.colors.corp} !important;
    svg {
      path {
        stroke: ${theme.colors.corp} !important;
      }
    }
  }
`;

const FlagLogo = styled(ImageComponent)`
  width: 140px;
  margin-left: 8px;
`;
const OrganizationInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  cursor: pointer;
  .clipboardIcon {
    border: none;
    outline: none;
  }

  .orgAvatar {
    margin-left: -16px;
    > div:first-of-type {
      background-color: ${theme.colors.comp['02']} !important;
    }
  }
`;
type Route = {
  title: string;
  linkTo: string;
  isExternalLink?: boolean;
  show: boolean;
  isActive?: (_: any, location: { pathname: string | string[] }) => any;
  inlineManualData?: string;
  badge?: string;
};

type User = {
  firstName: string;
  lastName: string;
  email: string;
  school?: string;
  crmId?: string;
};

type OrganizationInfo = {
  title: string | null | undefined;
  subtitle: string | null | undefined;
  onClick: () => void | null | undefined;
  id: string | null | undefined;
  copyClipboardText: string | null | undefined;
  copiedClipboardText: string | null | undefined;
};

type Props = {
  user: User;
  routes: Route[];
  translate: any;
  loadingAssessor: boolean;
  organizationInfo?: OrganizationInfo;
  handleGetAssessor?: (crmId?: string, schoolId?: string) => void;
  handleGoToSettings?: () => void;
  handleLogout: () => void;
  setLoggingData: (event: string, eventProperties?: {}) => void;
  className?: string;
  showHelpButton?: boolean;
  helpWAButton?: JSX.Element;
  studentViewButton?: JSX.Element;
  showFlag?: boolean;
  isDistrictAdmin?: boolean;
  isStudent?: boolean;
};

export function Topbar(props: Props) {
  const {
    user,
    routes,
    translate,
    loadingAssessor,
    organizationInfo,
    handleGetAssessor,
    handleGoToSettings,
    className,
    showHelpButton,
    helpWAButton,
    handleLogout,
    setLoggingData,
    studentViewButton,
    showFlag,
    isDistrictAdmin,
    isStudent,
  } = props;

  const setLoggingEvent = (route: Route) => {
    setLoggingData('change_top_menu', {
      top_menu: route.linkTo,
    });
  };

  const setEvent = (routes: Route[]) => {
    const route = routes.find((route) => route.linkTo);

    setLoggingData('change_page', {
      top_menu: route?.linkTo,
    });
  };

  const { isInWebview, sendEventToWebview, isGreenApp } = useWebviewBridge();

  const createBadge = (route: Route) => {
    if (route.badge) {
      return (
        <StyledBadge backgroundColor={theme.colors.navy.dark['01']}>
          {route.badge}
        </StyledBadge>
      );
    } else {
      return null;
    }
  };

  const getRouteLink = (route: Route, index: number) => {
    if (!route.show) return undefined;
    if (route.isExternalLink) {
      return (
        <a
          href={`${window.location.origin}${route.linkTo}`}
          id={`${index}-${route.title}`}
          key={`${index}-${route.title}`}
          data-inlinemanual={route.inlineManualData}
          onClick={() => setLoggingEvent(route)}
          data-testid={`${index}-${route.inlineManualData}`}
        >
          <h6 className="h6">{route.title}</h6>
          {createBadge(route)}
        </a>
      );
    }
    return (
      <NavLink
        id={`${index}-${route.title}`}
        key={`${index}-${route.title}`}
        activeClassName={'topbarSelectedLink'}
        isActive={route.isActive}
        data-inlinemanual={route.inlineManualData}
        to={route.linkTo}
        onClick={() => setLoggingEvent(route)}
        data-testid={`${index}-${route.inlineManualData}`}
      >
        <h6 className="h6">{route.title}</h6>
        {createBadge(route)}
      </NavLink>
    );
  };

  const toggleInlineManual = () => {
    (window as any).inline_manual_player?.togglePanel();
  };

  const FlagImageSrc = normalizeImgSource(UEFlag);

  return (
    <TopBar className={className}>
      <LogoContainer>
        {(!isInWebview && !isGreenApp) || isStudent ? (
          <Link to="/" onClick={() => setEvent(routes)}>
            <Logo />
          </Link>
        ) : (
          <GlimmerButton
            variant="tertiary"
            loading={false}
            onClick={() => {
              sendEventToWebview(CLOSE_WEBVIEW);
            }}
            leftIcon="BackIcon"
          >
            {translate('back.button')}
          </GlimmerButton>
        )}
        {showFlag && <FlagLogo src={FlagImageSrc} alt="flag" />}

        {organizationInfo && (
          <OrganizationInfoWrapper>
            <AvatarCard
              onClick={organizationInfo.onClick}
              className="orgAvatar"
            >
              <AvatarCard.Avatar text={organizationInfo.title || ''} />
              <AvatarCard.Text subtitle={organizationInfo.subtitle || ''}>
                {organizationInfo.title}
              </AvatarCard.Text>
            </AvatarCard>
            {!isDistrictAdmin && (
              <CopyToClipboardButton
                textToCopy={organizationInfo.id!}
                copiedTooltip={organizationInfo.copiedClipboardText!}
                copyTooltip={organizationInfo.copyClipboardText!}
              />
            )}
          </OrganizationInfoWrapper>
        )}
      </LogoContainer>
      <NavOptions>{routes.map(getRouteLink)}</NavOptions>

      {helpWAButton}
      <UserConfig>
        {showHelpButton && (
          <HelpButton
            onClick={toggleInlineManual}
            ripple={false}
            icon="OpenBook"
            color="default"
          >
            {translate('topbar.helpCenter.button')}
          </HelpButton>
        )}

        {studentViewButton}
        {user && (
          <Account
            user={user}
            translate={translate}
            handleGetAssessor={handleGetAssessor}
            handleGoToSettings={
              handleGoToSettings ? handleGoToSettings : undefined
            }
            handleLogout={handleLogout}
            loadingAssessor={loadingAssessor}
            setLoggingData={setLoggingData}
          />
        )}
      </UserConfig>
    </TopBar>
  );
}
