import { Link, useLocation } from 'react-router-dom';
import { Icon, selectIcons } from '@innovamat/innova-components';
import { setEventData } from '../../utils/setEventData';

import styles from './MenuItem.module.scss';
import { LOAD_ACTIVITY, useWebviewBridge } from '@innovamat/webview-bridge';
import i18n from '../../config/i18n';
import { useSitemapItems } from '../../hooks/use-sitemap/use-sitemap-items';
import type { SitemapItem } from '../../hooks/use-sitemap/sitemap';
import useRegionParameters from '../../hooks/use-region-parameters';
import { useUser } from '../../hooks/use-user';
import { usePlatformUrl } from '../../hooks/use-platform-url';

type MenuItemProps = {
  item: SitemapItem;
  course: number;
  className?: string;
};

export const getLink = (item: SitemapItem): string => {
  if (item.linkItemType === 'ROUTE') {
    return item.route!;
  }
  if (item.linkItemType === 'EXTERNAL') {
    return item.url!;
  }
  return item.urlName!;
};

const getIcon = (value: number) =>
  selectIcons.find((icon) => icon.value === value)?.key;

const MenuItem = ({ item, className }: MenuItemProps): JSX.Element => {
  const { pathname } = useLocation();
  const { value, linkItemType, icon, demoPageId, fullPageId } = item;
  const pageId = fullPageId || demoPageId;
  const isExternal = linkItemType === 'EXTERNAL';

  const { getTypeOfPagePermission, topMenu } = useSitemapItems();

  const checkIsActive = (link: string | null): boolean => {
    const isActive = link === pathname;
    return isActive;
  };

  const link = getLink(item);

  const isMenuDisabled = (): boolean => {
    const itemDisabled = item.status === 'DISABLED';
    const isPage = linkItemType === 'PAGE_ID';

    if (itemDisabled) return true;

    return isPage && !pageId;
  };

  const iconKey = getIcon(icon);

  const isActive = checkIsActive(link);
  const isDisabled = isMenuDisabled();

  let extraclasses = isActive ? styles.active : '';
  extraclasses += isDisabled ? styles.disabled : '';

  const ItemContent = (): JSX.Element => (
    <>
      {iconKey && (
        <span className={styles.iconWrapper}>
          <Icon icon={iconKey} className={styles.icon} />
        </span>
      )}
      <span className={styles.label}>{value}</span>
    </>
  );

  const linkKey = link?.split('/').reverse().shift()!;

  const isManipulative = linkKey === 'manipulative';
  const isReports = linkKey === 'reports';

  const handleItemClick = (): void => {
    const permissionType = getTypeOfPagePermission(item);

    if (isManipulative) {
      setEventData('content_manipulative_access', {
        opened_from: 'mainMenu',
      });
    } else {
      setEventData('change_page', {
        top_menu: topMenu,
        page_id: pageId,
        page_name: value,
        page_type: permissionType,
      });
    }
  };

  const { isInWebview, sendEventToWebview } = useWebviewBridge();
  const { regionParameters } = useRegionParameters();

  const { platformUrl } = usePlatformUrl();
  const { educationLevel, tokenInfo } = useUser();

  const handleOpenApplet = (): void => {
    isInWebview &&
      sendEventToWebview(LOAD_ACTIVITY, {
        sceneName: 'ManipulativeZone',
        packOrVariation: 0,
        regionParameters,
        platformApiUrl: platformUrl,
        accessToken: tokenInfo?.access_token,
        refreshToken: tokenInfo?.refresh_token,
        idToken: tokenInfo?.id_token,
        language: i18n.language,
        isSecondary:
          educationLevel === 'SECUNDARIA' || educationLevel === 'SECONDARY',
      });
  };

  const LinkType = (): JSX.Element =>
    isExternal || isManipulative ? (
      <a
        href={isInWebview ? '#manipulative' : link}
        target={isInWebview ? '' : '_blank'}
        rel="noopener noreferrer"
        onClick={
          isInWebview && isManipulative ? handleOpenApplet : handleItemClick
        }
      >
        <ItemContent />
      </a>
    ) : (
      <>
        {(!isReports || !isInWebview) && (
          <Link to={link} onClick={handleItemClick}>
            <ItemContent />
          </Link>
        )}
      </>
    );

  return (
    <>
      <li
        className={`${styles.menuItem} ${className} ${extraclasses}`}
        data-tip
        data-for={icon}
      >
        <LinkType />
      </li>
    </>
  );
};

export default MenuItem;
