import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Routes from '../routes/routes';

// Define dynamic imports for all languages
const dynamicImports = {
  es: async () => {
    const { gaTranslations, reportsTranslations, geogebraTranslations } =
      await import('@innovamat/poeditor');

    return {
      translations: {
        ...gaTranslations.es,
        ...reportsTranslations.es,
        ...geogebraTranslations.es,
      },
      routes: Routes.es,
    };
  },
  ca: async () => {
    const { gaTranslations, reportsTranslations, geogebraTranslations } =
      await import('@innovamat/poeditor');

    return {
      translations: {
        ...gaTranslations.ca,
        ...reportsTranslations.ca,
        ...geogebraTranslations.ca,
      },
      routes: Routes.ca,
    };
  },
  eu: async () => {
    const { gaTranslations, reportsTranslations, geogebraTranslations } =
      await import('@innovamat/poeditor');

    return {
      translations: {
        ...gaTranslations.eu,
        ...reportsTranslations.eu,
        ...geogebraTranslations.eu,
      },
      routes: Routes.eu,
    };
  },
  en: async () => {
    const { gaTranslations, reportsTranslations, geogebraTranslations } =
      await import('@innovamat/poeditor');

    return {
      translations: {
        ...gaTranslations.en,
        ...reportsTranslations.en,
        ...geogebraTranslations.en,
      },
      routes: Routes.en,
    };
  },
  it_IT: async () => {
    const { gaTranslations, reportsTranslations, geogebraTranslations } =
      await import('@innovamat/poeditor');

    return {
      translations: {
        ...gaTranslations.it,
        ...reportsTranslations.it,
        ...geogebraTranslations.it,
      },
      routes: Routes.it_IT,
    };
  },
  en_US: async () => {
    const { gaTranslations, reportsTranslations, geogebraTranslations } =
      await import('@innovamat/poeditor');

    return {
      translations: {
        ...gaTranslations.en_us,
        ...reportsTranslations.en_us,
        ...geogebraTranslations.en_us,
      },
      routes: Routes.en_US,
    };
  },
  pt_BR: async () => {
    const { gaTranslations, reportsTranslations, geogebraTranslations } =
      await import('@innovamat/poeditor');

    return {
      translations: {
        ...gaTranslations.pt_br,
        ...reportsTranslations.pt_br,
        ...geogebraTranslations.pt_br,
      },
      routes: Routes.pt_BR,
    };
  },
  va: async () => {
    const { gaTranslations, reportsTranslations, geogebraTranslations } =
      await import('@innovamat/poeditor');

    return {
      translations: {
        ...gaTranslations.va_es,
        ...reportsTranslations.va_es,
        ...geogebraTranslations.va_es,
      },
      routes: Routes.va,
    };
  },
  es_LA: async () => {
    const { gaTranslations, reportsTranslations, geogebraTranslations } =
      await import('@innovamat/poeditor');

    return {
      translations: {
        ...gaTranslations.es_la,
        ...reportsTranslations.es_la,
        ...geogebraTranslations.es_la,
      },
      routes: Routes.es_LA,
    };
  },
  // Add other languages similarly...
};

const resources = {};

// Populate resources with the dynamic imports
Object.keys(dynamicImports).forEach(async (key) => {
  resources[key] = await dynamicImports[key]();
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
i18n.use(initReactI18next).init({
  lng: 'es',
  resources,
  fallbackLng: (code: string) => {
    if (!code || code === '') return 'es';
    return code.includes('es_') ? 'es_LA' : code;
  },
  whitelist: Object.keys(resources),
  debug: false,
  ns: ['translations', 'routes'],
  defaultNS: 'translations',
  keySeparator: false,
  nsSeparator: '::',
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    wait: true,
  },
});

export default i18n;
