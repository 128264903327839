import ContentLoader from 'react-content-loader';

export default function CircleLoader(props: any) {
  const width = props.width || 240;
  const height = props.height || 16;

  const value = width / 2;
  return (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <circle cx={value} cy={value} r={value} />;
    </ContentLoader>
  );
}
