import { ThemeProvider } from 'styled-components';
import { ThemeProvider as ThemeProviderGlimmerComponents } from '@innovamat/glimmer-components';

import { GlobalStyle, theme } from './theme';

type Props = {
  children: React.ReactNode;
};

export default function ThemeWrapper({ children }: Props) {
  return (
    <ThemeProvider theme={theme}>
      <ThemeProviderGlimmerComponents type={'light'}>
        <>
          <GlobalStyle />
          {children}
        </>
      </ThemeProviderGlimmerComponents>
    </ThemeProvider>
  );
}
