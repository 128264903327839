import { useState } from 'react';
import { Icon } from '@innovamat/innova-components';
import {
  DropdownHeader,
  Title,
  DropdownContent as DropdownContentStyled,
} from './DropdownContent.styled';

type Props = {
  title: string;
  children: JSX.Element;
  defaultOpen?: boolean;
  dataInlineManual: string;
  testIdButton?: string;
  testIdDropdown?: string;
};

export default function DropdownContent({
  title,
  children,
  defaultOpen = false,
  dataInlineManual,
  testIdButton,
  testIdDropdown,
}: Props) {
  const [openDropdown, setOpenDropdown] = useState(defaultOpen);

  const handleOpenDropdown = () => {
    setOpenDropdown((prevState) => !prevState);
  };

  return (
    <div>
      <DropdownHeader
        role="presentation"
        data-inlinemanual={dataInlineManual}
        onClick={handleOpenDropdown}
        data-testid={testIdButton}
      >
        <Title>{title}</Title>
        <Icon icon={openDropdown ? 'ArrowUp' : 'ArrowDown'} size="sm" />
      </DropdownHeader>
      {openDropdown && (
        <DropdownContentStyled data-testid={testIdDropdown}>
          {children}
        </DropdownContentStyled>
      )}
    </div>
  );
}
