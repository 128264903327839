import styled from 'styled-components';
import { bannerHeight } from '../HomeLayout/HomeLayout.styled';

const Wrapper = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.corp};
  color: white;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  height: ${bannerHeight};
  justify-content: center;
  padding: 0 128px;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 10;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    padding: 0 16px;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: none;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  fill: white;
  position: absolute;
  right: 32px;
  width: 10px;
`;

export { Wrapper, IconContainer };
