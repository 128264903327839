import { useWindowSize } from '@innovamat/hooks';
import { useEffect } from 'react';

function useAppHeight(): void {
  const { height } = useWindowSize();

  useEffect(() => {
    if (height !== undefined) {
      const doc = document.documentElement;
      doc.style.setProperty('--vh', `${height * 0.01}px`);
    }
  }, [height]);
}

export default useAppHeight;
