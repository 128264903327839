import { lazy, Suspense } from 'react';

const LandingParent = lazy(() => import('../LandingParent'));
const Reports = lazy(() => import('../reports'));
const WeeklyPractice = lazy(() => import('../WeeklyPractice'));
const Page = lazy(() => import('../Page'));
const Settings = lazy(() => import('../my-settings-wrapper'));
const E404 = lazy(() => import('../Errors/E404'));
const ResourceRouter = lazy(() => import('../ResourceRouter'));
const PrintGuides = lazy(() => import('../PrintGuides'));
// Loading component to be shown while the lazily loaded components are loading
const Loading = () => <div></div>;

// Wrap your components with Suspense to handle loading
const LazyLandingParent = () => (
  <Suspense fallback={<Loading />}>
    <LandingParent />
  </Suspense>
);

const LazyReports = () => (
  <Suspense fallback={<Loading />}>
    <Reports />
  </Suspense>
);

const LazyWeeklyPractice = () => (
  <Suspense fallback={<Loading />}>
    <WeeklyPractice />
  </Suspense>
);

const LazyPage = (props: any) => (
  <Suspense fallback={<Loading />}>
    <Page {...props} />
  </Suspense>
);

const LazySettings = () => (
  <Suspense fallback={<Loading />}>
    <Settings />
  </Suspense>
);

const LazyE404 = () => (
  <Suspense fallback={<Loading />}>
    <E404 />
  </Suspense>
);

const LazyResourceRouter = () => (
  <Suspense fallback={<Loading />}>
    <ResourceRouter />
  </Suspense>
);

const LazyPrintGuides = () => (
  <Suspense fallback={<Loading />}>
    <PrintGuides />
  </Suspense>
);

export {
  LazyLandingParent,
  LazyReports,
  LazyWeeklyPractice,
  LazyPage,
  LazySettings,
  LazyE404,
  LazyResourceRouter,
  LazyPrintGuides,
};
