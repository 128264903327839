import styled from '@emotion/styled';
import { theme } from '@innovamat/innova-components';

const Container = styled.div`
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.dark['05']};
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 8px;
  position: absolute;
  right: 0;
  z-index: 10;
`;

const AvatarContainer = styled.div`
  height: 40px;
  pointer-events: none;
  width: 40px;
`;

const UserInfo = styled.div`
  border-bottom: 1px solid ${theme.colors.dark['04']};
  display: flex;
  flex-direction: row;
  font-size: ${theme.sizes.normal};
  padding-bottom: 16px;
  align-items: center;
  justify-content: center;
`;

const InfoContainer = styled.div`
  align-content: center;
  display: grid;
  margin: 0 16px;

  .name {
    color: ${theme.colors.black};
    flex: 1;
    font-size: ${theme.sizes.normal};
    font-weight: bold;
    padding-top: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .email {
    align-items: center;
    color: ${theme.colors.dark['03']};
    display: flex;
    flex: 1;
    font-size: ${theme.sizes.xsmall};
    gap: 8px;
    margin-top: 2px;
    white-space: nowrap;

    &:hover {
      .copyClipboard {
        opacity: 1;
        transition: opacity 0.3s;
      }
    }
  }
`;

const Actions = styled.div<{ hasBorder: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 8px 0;

  border-bottom: ${({ hasBorder }) =>
    hasBorder ? `1px solid ${theme.colors.dark['04']}` : 'none'};
`;

const Action = styled.div`
  color: ${theme.colors.black};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: ${theme.sizes.small};
  padding: 8px 0;
  text-decoration: none;
  white-space: nowrap;
  align-items: center;

  &:hover {
    color: ${theme.colors.corp};

    .iconContainer svg {
      path,
      circle {
        stroke: ${theme.colors.corp};
      }
    }
  }
`;

const IconContainer = styled.div`
  height: auto;
  margin: -4px;
  margin-left: 8px;
  height: 16px;
  width: 16px;

  svg {
    path,
    circle {
      stroke: ${theme.colors.black};
    }
  }
`;

const CopyClipboard = styled.div`
  cursor: pointer;
  height: auto;
  opacity: 0;
  transition: opacity 0.3s;
  width: 16px;

  svg {
    path,
    circle {
      stroke: ${theme.colors.dark['03']};
    }
  }
`;

const LogOutContainer = styled.div`
  padding-top: 8px;
`;

const LogOut = styled(Action)`
  color: ${theme.colors.dark['03']};

  .iconContainer svg {
    path,
    circle {
      stroke: ${theme.colors.dark['03']};
    }
  }
`;

const StyledPhone = styled.a`
  align-items: center;
  color: ${theme.colors.corp};
  display: flex;
  font-size: 12px;
  gap: 8px;
  padding: 12px 0 0;
  text-decoration: none;
`;

const WhatsappIcon = styled.div`
  cursor: pointer;
  height: auto;
  width: 16px;

  svg {
    path,
    circle {
      stroke: ${theme.colors.corp};
    }
  }
`;

const StyledEmail = styled.div`
  align-items: center;
  color: ${theme.colors.dark['03']};
  display: flex;
  flex: 1;
  font-size: ${theme.sizes.xsmall};
  gap: 8px;
  margin-top: 2px;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    .copyClipboard {
      opacity: 1;
      transition: opacity 0.3s;
    }
  }
`;

const AssessorTitle = styled.div`
  margin: 16px 0 8px;
  font-size: 16px;
  font-weight: 400;
`;

export {
  Container,
  AvatarContainer,
  UserInfo,
  InfoContainer,
  Actions,
  Action,
  IconContainer,
  CopyClipboard,
  LogOutContainer,
  LogOut,
  StyledPhone,
  WhatsappIcon,
  StyledEmail,
  AssessorTitle,
};
