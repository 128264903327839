import { authService } from '@innovamat/radiance-utils';
import { eventLogging } from '@innovamat/event-logging';
import { detectIsInWebview, detectIsInIOS } from '@innovamat/webview-bridge';

const {
  VITE_USERS_CLIENT_ID,
  VITE_ITERABLE_APIKEY,
  VITE_RUDDERSTACK_APIKEY,
  VITE_RUDDERSTACK_URL,
  VITE_ENVIRONMENT,
} = import.meta.env;

const init = (apiUsers: string) => {
  authService.init(apiUsers, VITE_USERS_CLIENT_ID, VITE_ENVIRONMENT);
  eventLogging.init({
    iterableApiKey: VITE_ITERABLE_APIKEY,
    rudderstackApiKey: VITE_RUDDERSTACK_APIKEY,
    rudderstackUrl: VITE_RUDDERSTACK_URL,
    isInWebview: detectIsInWebview(),
    isInIOS: detectIsInIOS(),
    isInLocal:
      VITE_ENVIRONMENT !== 'production' && VITE_ENVIRONMENT !== 'staging',
  });
};

export default init;
