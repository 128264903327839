import { useDevice } from '@innovamat/glimmer-components';
import { useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

type CollapseSidebar = {
  isCollapsed: boolean;
  onToggle: () => void;
  onCollapse: () => void;
  onExpand: () => void;
};

const ROUTES_TO_BE_CLOSED = ['/reports'];

export function useCollapseSidebar(): CollapseSidebar {
  const { isTablet } = useDevice();
  const queryClient = useQueryClient();
  const location = useLocation();

  const query = useQuery<boolean>({
    queryKey: ['collapseSidebar'],
    queryFn: () => isTablet,
    staleTime: Infinity,
  });

  const handleCollapse = (value: boolean): void => {
    queryClient.setQueryData(['collapseSidebar'], value);
  };

  useEffect(() => {
    if (!isInClosedRoute) {
      queryClient.setQueryData(['collapseSidebar'], isTablet);
    }
  }, [isTablet, queryClient]);

  const isInClosedRoute = ROUTES_TO_BE_CLOSED.some((route) =>
    location.pathname.includes(route)
  );

  return {
    isCollapsed: isInClosedRoute || Boolean(query.data),
    onToggle: () => handleCollapse(!query.data),
    onCollapse: () => handleCollapse(true),
    onExpand: () => handleCollapse(false),
  };
}
