import styled from 'styled-components';
import { Button, ModalV2 as Modal, theme } from '@innovamat/innova-components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as WhatsApp } from '../../assets/svg/whatsapp.svg';
import { setEventData } from '../../utils/setEventData';
import { useUser } from '../../hooks/use-user';
import { snack } from '@innovamat/glimmer-components';

const ModalTitle = styled.h1`
  margin-top: 10px;
  color: ${theme.colors.dark['01']};
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
`;

const ModalDescription = styled.p`
  font-size: 14px;
  color: ${theme.colors.dark['02']};
  line-height: 20px;
  font-weight: 300;
`;

const NewModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: auto;
  margin-bottom: 24px;
  max-width: 514px;
`;

const CustomFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
type NeedHelpModalProps = {
  onClose: () => void;
  isModalOpen: boolean;
};

const PHONE_NUMBER_KEY = ['phoneNumber'];

const useGetPhoneNumber = (school: string | undefined) => {
  const query = useQuery<string, Error>({
    queryKey: PHONE_NUMBER_KEY,
    queryFn: async () =>
      await fetch(
        `https://hook.eu1.make.com/x63uw5hh5tcuofdxnzaapt8gnirkwk6h?uuid=${school}&t=1`
      ).then((res) => res.text().then((text) => text)),
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
  });

  return query;
};

const NeedHelpModal = ({ onClose, isModalOpen }: NeedHelpModalProps) => {
  const { t } = useTranslation();
  const { organizationId } = useUser();
  const { error, isError, isLoading } = useGetPhoneNumber(organizationId);

  const goToWhatsApp = () => {
    // Uncomment when more than one number
    // if (phoneNumber && isPhoneNumberValid) {
    //   setLoggingData('open_whatsapp');
    //   window.open(`https://wa.me/${phoneNumber.replace(/\s/g, '')}`, '_blank');
    // }
    setEventData('open_whatsapp');
    window.open(`https://wa.me/+34648904878`, '_blank');
  };

  useEffect(() => {
    if (isModalOpen && isError)
      snack.error(t('E500.Lo sentimos, ha ocurrido un error'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isModalOpen, error]);

  return (
    <Modal
      open={isModalOpen}
      onClose={onClose}
      size="small"
      showCloseBtn
      clickOutsideClose
      customFooter={
        <CustomFooter>
          <ModalDescription>{t('whatsapp.modal.schedule')}</ModalDescription>
          <Button
            size="large"
            onClick={goToWhatsApp}
            data-inlinemanual="submit"
            loading={isLoading}
            // Uncomment when more than one number
            // disabled={isError || !phoneNumber || !isPhoneNumberValid}
          >
            {t('whatsapp.modal.button')}
          </Button>
        </CustomFooter>
      }
      padding="32px 24px"
    >
      <NewModalWrapper>
        <WhatsApp />
        <ModalTitle>{t('whatsapp.modal.title')}</ModalTitle>
        <ModalDescription>{t('whatsapp.modal.description')}</ModalDescription>
      </NewModalWrapper>
    </Modal>
  );
};

export default NeedHelpModal;
