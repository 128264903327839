const CustomCloseIcon = (props: any) => (
  <svg
    width={14}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M13 1 1 13M1 1l12 12" stroke="#000" strokeOpacity={0.6} />
  </svg>
);

export default CustomCloseIcon;
