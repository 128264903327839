import { Tooltip } from '@innovamat/glimmer-components';
import { Icon } from '@innovamat/innova-components';
import { useCopyToClipboard } from '@innovamat/hooks';

type CopyToClipboardButtonProps = {
  copiedTooltip: string;
  copyTooltip: string;
  textToCopy: string;
};

export function CopyToClipboardButton({
  copyTooltip,
  copiedTooltip,
  textToCopy,
}: CopyToClipboardButtonProps) {
  const [copiedText, copy] = useCopyToClipboard();

  return (
    <Tooltip
      content={copiedText !== textToCopy ? copyTooltip : copiedTooltip}
      appendTo="parent"
      hideOnClick={false}
    >
      <Icon
        icon="Copy"
        onClick={() => copy(textToCopy || '')}
        className="clipboardIcon"
      />
    </Tooltip>
  );
}
