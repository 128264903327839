import { authAxiosInstance } from '@innovamat/ga-features';
import { GetSitemapBody, Sitemap } from './sitemap.d';

const API_SITEMAPS = import.meta.env.VITE_API_SITEMAPS;

export const getSitemap = async (
  params: GetSitemapBody
): Promise<Sitemap[]> => {
  const url = `${API_SITEMAPS}/sitemap-tree`;
  const response = await authAxiosInstance.get(url, {
    params,
  });

  return response.data;
};
