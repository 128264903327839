import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import whatsapp from '../../assets/images/wa_avatar.png';
import { Icon } from '@innovamat/innova-components';
import { NeedHelpButton as HelpBtn } from '@innovamat/glimmer-components';
import { Avatar } from '@innovamat/glimmer-components';

const FloatingContainer = styled.div`
  height: 48px;
  position: absolute;
  right: 80px;
  top: 12px;
  z-index: 1;
`;

const IconWrapper = styled.div`
  padding-bottom: 4px;
  padding-top: 4px;
  display: flex;
  align-items: center;
`;

type Props = {
  onClick: () => void;
};

export default function NeedHelpButton({ onClick }: Props) {
  const { t } = useTranslation();
  return (
    <FloatingContainer>
      <HelpBtn onClick={onClick}>
        <HelpBtn.Avatar>
          <Avatar alt={'avatar-needhelp'} img={whatsapp} size="S" />
        </HelpBtn.Avatar>
        <HelpBtn.Content isResponsive>
          <HelpBtn.Title>{t('whatsapp.button.title')}</HelpBtn.Title>
          <HelpBtn.Description>
            {t('whatsapp.button.description')}
          </HelpBtn.Description>
        </HelpBtn.Content>
        <IconWrapper>
          <Icon icon={'ArrowRight'} size="sm" />
        </IconWrapper>
      </HelpBtn>
    </FloatingContainer>
  );
}
