import { useEffect } from 'react';
import { useLocation } from 'react-router';

// Manager has a different body styles, we need to add a style when is Manager route
export default function useAppLayout() {
  const location = useLocation();

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]!;

    if (location.pathname.includes('/manager')) {
      body.style.overflowY = 'scroll';
    } else {
      body.style.overflowY = 'none';
    }
  }, [location.pathname]);
}
