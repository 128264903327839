import { InlineManualUser } from '@innovamat/radiance-utils';
import { User } from './user.d';

export const mapUserToInlineManualUser = (
  user: User,
  isInIOS?: boolean,
  isInWebView?: boolean
): InlineManualUser => ({
  uid: user.id,
  username: user.email,
  name: user.firstName,
  surname: user.lastName,
  roles: user.roles,
  group: user.organizationName,
  uuid: user.organizationId,
  crmid: user.crmId || null,
  language: user.language === 'va' ? 'ca' : user.language,
  region: user.region,
  os: isInIOS && isInWebView ? 'ios' : isInWebView ? 'android' : undefined,
});
