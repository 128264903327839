import { SessionDoneProvider } from '@innovamat/ga-features';
import { Classroom } from '@innovamat/radiance-utils';
import { setEventData } from '../../utils/setEventData';
import { useTranslation } from 'react-i18next';
import { useClassrooms } from '../../hooks/use-classrooms';
import { useUser } from '../../hooks/use-user';
import { useWebviewBridge } from '@innovamat/webview-bridge';
import useResourceNavigation from '../../hooks/useResourceNavigation';

type Props = {
  children: React.ReactNode;
};

const ERROR_URL = import.meta.env.VITE_INTEGROMAT_SESSION_ERROR as string;
const REVIEW_URL = import.meta.env.VITE_INTEGROMAT_SESSION_REVIEW as string;

export function SessionDoneWrapper({ children }: Props): JSX.Element {
  const {
    organization,
    classroomId,
    currentCourse,
    courseOrder,
    language,
    roles,
    region,
    email,
    name,
    lastName,
  } = useUser();
  const { isInWebview } = useWebviewBridge();
  const { data: classrooms } = useClassrooms();
  const { t } = useTranslation();
  const { getPathToResource } = useResourceNavigation();

  const handleGoToApplet = (appletId: string): void => {
    if (isInWebview) return;
    const path = getPathToResource('applet', appletId);
    window.open(path, '_blank');
  };

  return (
    <SessionDoneProvider
      academicYearId={organization?.academicYearId!}
      classroomId={classroomId}
      courseOrder={courseOrder}
      region={region!}
      locale={language}
      classrooms={classrooms as Classroom[]}
      courseId={currentCourse?.id!}
      email={email!}
      t={t}
      setEventData={setEventData}
      userRoles={roles!}
      onGoToApplet={handleGoToApplet}
      webhooksInfo={{
        INTEGROMAT_ERROR_WEBHOOK_URL: ERROR_URL,
        INTEGROMAT_REVIEW_WEBHOOK_URL: REVIEW_URL,
        courseName: currentCourse?.name!,
        lastName: lastName!,
        name: name!,
        organizationId: organization?.id!,
        organizationName: organization?.name!,
      }}
    >
      {children}
    </SessionDoneProvider>
  );
}
