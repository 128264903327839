/* eslint-disable */
import { DateTime } from 'luxon';

export const getRemainingDays = (date: string | undefined) => {
  if (!date) return;
  const now = DateTime.local();
  const future = DateTime.fromSQL(date);
  const diff = future.diff(now, 'day');
  return Math.floor(diff.days);
};
