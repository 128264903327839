import {
  Wrapper,
  Image,
  Content,
  Title,
  Text,
  StyledButton,
  IconContainer,
} from './Banner.Styles';
import CustomCloseIcon from './CustomCloseIcon';

type Props = {
  colour: string;
  showBtn: boolean;
  btnText?: string;
  title: string;
  text: string;
  imgSrc: string;
  imgAlt: string;
  isBtnLoading?: boolean;
  canBeClosed?: boolean;
  onBtnClick?: () => void;
  onBtnCloseClick?: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

export default function Banner({
  showBtn,
  colour,
  btnText,
  imgSrc,
  imgAlt,
  title,
  text,
  isBtnLoading,
  onBtnClick,
  onBtnCloseClick,
  canBeClosed,
  ...rest
}: Props) {
  const handleCloseBanner = () => {
    onBtnCloseClick?.();
  };

  const handleBtnClick = () => {
    onBtnClick?.();
  };

  return (
    <Wrapper {...rest}>
      <Image src={imgSrc} alt={imgAlt} />
      <Content bg={colour} showBtn={showBtn}>
        <div>
          <Title text={title} />
          <Text text={text} />
        </div>
        {showBtn && (
          <StyledButton
            loading={isBtnLoading}
            bg={colour}
            onClick={handleBtnClick}
          >
            {btnText}
          </StyledButton>
        )}
      </Content>

      {onBtnCloseClick && canBeClosed && (
        <IconContainer>
          <CustomCloseIcon onClick={handleCloseBanner} />
        </IconContainer>
      )}
    </Wrapper>
  );
}
